import { RouterSlot, UIRouter } from '@zazcart/commons';
import { GlobalEventsHandlerProvider } from '@zazcart/gestalt';
import { AnimatePresence } from 'framer-motion';
import React, { PropsWithChildren, useMemo } from 'react';
import { AppLayout } from '~/components/AppLayout';
import { Loadable } from '~/components/loadable.tsx';
import { MainHeader } from '~/components/main-header/main-header.tsx';
import { ProductsLoaderIndicator } from '~/components/product-list';
import { SidebarRight } from '~/components/sidebar';
import { SidebarDefault } from '~/components/sidebar/sidebar-default.tsx';
import { createRouter } from '~/pages/__root.tsx';

export function RouterProvider(props: PropsWithChildren<{ url: string }>) {
  const { url } = props;

  const router = useMemo(() => createRouter(), []);

  const linkHandlers = useMemo(() => {
    return {
      onNavigation: ({ href }: any) => {
        return ({ event }: any) => {
          event.preventDefault();
          if (href === '#') return;
          router.$history.push(href);
        };
      },
    };
  }, []);

  return (
    <UIRouter url={url} router={router}>
      <GlobalEventsHandlerProvider linkHandlers={linkHandlers}>
        <AnimatePresence>
          <Loadable
            loadingIndicator={
              <AppLayout
                navbar={<MainHeader />}
                hero={null}
                left={<SidebarDefault />}
                right={<SidebarRight />}
                main={<ProductsLoaderIndicator />}
              />
            }
          >
            <RouterSlot NotFound={NotFound} />
          </Loadable>
        </AnimatePresence>
      </GlobalEventsHandlerProvider>
    </UIRouter>
  );
}

function NotFound() {
  return <h1>Página não encontrada</h1>;
}
