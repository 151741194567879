import './MainSearch.scss';

import {
  cx,
  useMatchRoute,
  useNavigate,
  useSearch,
  useThrottle,
} from '@zazcart/commons';
import { Form, FormField, Icon, IconButton } from '@zazcart/ui';
import { default as React, useEffect, useRef, useState } from 'react';

const NAME = 'main_search';

export function MainSearch(props: { className?: string }) {
  const { className } = props;
  const looseSearch = useSearch();
  const navigate = useNavigate();
  const matchRoute = useMatchRoute();
  const [localSearch, setLocalSearch] = useState(looseSearch.s || '');

  // handle search focus on page change
  const [ref, setRef] = useState<HTMLInputElement | null>(null);
  const didFocus = useRef(false);
  useEffect(() => {
    if (!ref) return;
    if (didFocus.current) return;
    didFocus.current = true;
    if (looseSearch.s) {
      ref.focus();
    }
  }, [ref]);

  const syncQuery = useThrottle(
    (s: string) => {
      navigate({
        to: '/search',
        search: { s },
        replace: matchRoute('/search'),
      });
    },
    500,
    navigate,
  );

  return (
    <Form
      className={cx('MainSearch', className)}
      onSubmit={(values) => {
        const value = values[NAME];
        setLocalSearch(value);
        syncQuery(value);
      }}
    >
      {(formApi) => {
        const { active } = formApi;

        return (
          <FormField
            prefix={
              !active && (
                <Icon icon={'search'} size={'sm'} accessibilityLabel="busca" />
              )
            }
            suffix={
              localSearch && (
                <IconButton
                  size={'sm'}
                  accessibilityLabel="Limpar"
                  icon={'clear'}
                  onClick={() => {
                    formApi.form.change(NAME, '');
                    setLocalSearch('');
                    syncQuery('');
                  }}
                />
              )
            }
            name={NAME}
            ref={setRef}
            id="mainSearch"
            onChange={(event) => {
              event.preventDefault();
              const value = event.currentTarget.value;
              setLocalSearch(value);
              syncQuery(value);
            }}
            placeholder="Buscar"
            value={localSearch}
          />
        );
      }}
    </Form>
  );
}
