import {
  createRouter as createRouterCommons,
  defineRoutes,
} from '@zazcart/commons';

export const appRoutes = defineRoutes({
  home: {
    path: '/',
    component: () => import('./home.tsx'),
  },
  cart: {
    path: '/cart',
    component: () => import('./cart.tsx'),
  },
  checkout: {
    path: '/checkout',
    component: () => import('./checkout.tsx'),
  },
  paymentResult: {
    path: '/payment-result',
    component: () => import('./payment-result/payment-result.tsx'),
  },
  collections: {
    path: '/collections/:collection',
    component: () => import('./collections.tsx'),
  },
  orders: {
    path: '/orders',
    component: () => import('./orders.tsx'),
  },
  products: {
    path: '/products/:slug',
    component: () => import('./products.tsx'),
  },
  search: {
    path: '/search',
    component: () => import('./search.tsx'),
  },
  terms: {
    path: '/terms',
    component: () => import('./terms.tsx'),
  },
} as const);

export function createRouter() {
  return createRouterCommons({ routes: appRoutes });
}

export type AppRoutes = typeof appRoutes;

declare module '@zazcart/commons' {
  export interface AppRoutesConfig extends AppRoutes {}
}
