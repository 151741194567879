import {
  BrowserHistory,
  BrowserHistoryOptions,
  createBrowserHistory,
  createMemoryHistory,
  createPath,
  MemoryHistory,
  MemoryHistoryOptions,
} from 'history';

import {
  AnyRecord,
  GetRouteParams,
  InferObjectDefinition,
  IS_BROWSER,
  IsOptional,
  NullableToPartial,
  ObjectDefinitionInput,
  RouteMatcher,
  RouteUtils,
  values,
} from 'powership';
import { lazy, ReactNode } from 'react';

export type {
  BrowserHistory,
  BrowserHistoryOptions,
  MemoryHistory,
  MemoryHistoryOptions,
};

export { createBrowserHistory, createMemoryHistory, createPath };

export type RouteConfig = {
  path: string;
  component: () => Promise<{ default: () => ReactNode }>;
  query?: ObjectDefinitionInput;
};

export type RoutesConfig = Readonly<{ [K: string]: RouteConfig }>;

export interface AppRoutesConfig extends RoutesConfig {
  // to overload in app
}

export type RouteKey = Exclude<keyof AppRoutesConfig & {}, number>;

export type RouteParameters<Route extends RouteKey> =
  GetRouteParams<AppRoutesConfig[Route]['path']> extends infer R
    ? {
        [K in keyof R]: R[K] extends unknown
          ? [IsOptional<R[K]>] extends [true]
            ? string | undefined
            : string
          : never;
      } & {}
    : never;

export type SimpleRoute = RouteConfig & {
  match(route: string): AnyRecord | null;
  mount(config?: { query: AnyRecord }): string;
};

export type RouteMatch =
  | ({ params: { [K: string]: string | undefined } } & Omit<
      SimpleRoute,
      'component'
    > & {
        Component: () => ReactNode;
        preload(): Promise<void>;
      })
  | null;

export type UIRouterInstance<Routes extends RoutesConfig = AppRoutesConfig> = {
  $findRoute(url: string): RouteMatch;
  $history: BrowserHistory;
  $config: Routes;
} & {
  [K in Extract<keyof Routes, string>]: {
    [Sub in keyof Routes[K]]: Routes[K][Sub] & {};
  } & {
    Component: () => ReactNode;
    preload(): Promise<void>;
    match: RouteMatcher<K>['match'];
    mount: [InferObjectDefinition<Routes[K]['query']>] extends [never]
      ? (config?: {}) => string
      : (config: {
          query: NullableToPartial<InferObjectDefinition<Routes[K]['query']>>;
        }) => string;
  };
};

// just a type utility
export function defineRoutes<Routes extends RoutesConfig>(
  routes: Readonly<Routes>,
): Routes {
  return routes;
}

export function createRouter<
  Routes extends RoutesConfig = AppRoutesConfig,
>(config: { routes: Routes }): UIRouterInstance<Routes> {
  const { routes } = config;

  const routeMap: UIRouterInstance<Routes> = Object.create(null);

  const history = IS_BROWSER ? createBrowserHistory() : createMemoryHistory();

  Object.entries(routes).forEach(([key, definition]) => {
    if (definition.path in routeMap) {
      throw new Error(`Multiple routes found with path "${definition.path}".`);
    }

    const matcher = RouteUtils.createRouteMatcher(definition.path);

    const { component } = definition;

    // @ts-ignore
    routeMap[key] = {
      ...definition,
      Component: lazy(component),
      async preload() {
        await component();
      },
      match: matcher.match.bind(matcher),
      mount(conf?: any) {
        let url = '/' + RouteUtils.normalizePath(definition.path);
        if (conf?.query) {
          url += '?' + RouteUtils.stringifyQueryString(conf.query);
        }
        return url;
      },
    };
  });

  const sorted = RouteUtils.sortRoutes(values(routeMap));

  routeMap.$history = history;

  routeMap.$findRoute = function $findRoute(url: string): RouteMatch {
    const { pathname } = RouteUtils.parseURL(url);
    for (let i = 0; i < sorted.length; i++) {
      const result = sorted[i].match(pathname);
      if (result) {
        return { ...sorted[i], params: result } as RouteMatch;
      }
    }
    return null; // no match
  };

  routeMap.$config = config.routes;

  return routeMap;
}
