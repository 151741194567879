import './variants.scss';

import { ClassValue, cx } from '@zazcart/commons';
import React from 'react';

export type Breakpoint = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export type ResponsiveValue<T> = T | Partial<Record<Breakpoint, T>>;

export const breakpoints = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  // '2xl': '1536px',
} as const;

export type GestaltSpace =
  | 0
  | 100
  | 200
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 900
  | 1000
  | 1100
  | 1200
  | 1300
  | 1400
  | 1500
  | 1600;

export type SpaceToken = GestaltSpace | `${GestaltSpace}`;

export type RoundingToken =
  | 0
  | 100
  | 200
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 'circle'
  | 'pill';
export type FontSize = 100 | 200 | 300 | 400 | 500 | 600;
export type FontWeight = 'normal' | 'semibold' | 'bold';
export type FontFamily = 'default-latin' | 'default-japanese' | 'code';

export type ColorName =
  | 'gray-roboflow'
  | 'yellow-caramellow'
  | 'orange-firetini'
  | 'purple-mysticool'
  | 'green-matchacado'
  | 'teal-spabattical'
  | 'blue-skycicle'
  | 'pink-flaminglow'
  | 'red-pushpin';

export type ColorIntensity =
  | 0
  | 50
  | 100
  | 200
  | 300
  | 400
  | 450
  | 500
  | 550
  | 600
  | 700
  | 800
  | 900;

export type JustifyContent =
  | 'start'
  | 'end'
  | 'center'
  | 'between'
  | 'around'
  | 'evenly';
export type AlignItems = 'start' | 'end' | 'center' | 'baseline' | 'stretch';
export type Direction = 'row' | 'column' | 'row-reverse' | 'column-reverse';
export type Display =
  | 'none'
  | 'block'
  | 'inline'
  | 'inline-block'
  | 'flex'
  | 'grid';

export type StatusVariant =
  | 'info'
  | 'success'
  | 'warning'
  | 'error'
  | 'recommendation';
export type StatusState = 'base' | 'weak' | 'strong';

export type VariantProps = {
  ariaLabel?: string;

  display?: ResponsiveValue<Display>;
  position?: ResponsiveValue<'relative' | 'absolute' | 'fixed' | 'sticky'>;

  direction?: ResponsiveValue<Direction>;
  justify?: ResponsiveValue<JustifyContent>;
  align?: ResponsiveValue<AlignItems>;
  wrap?: ResponsiveValue<boolean>;
  gap?: ResponsiveValue<SpaceToken>;
  col?: ResponsiveValue<boolean>;
  column?: ResponsiveValue<boolean>;
  row?: ResponsiveValue<boolean>;

  m?: ResponsiveValue<SpaceToken>;
  mx?: ResponsiveValue<SpaceToken>;
  my?: ResponsiveValue<SpaceToken>;
  mt?: ResponsiveValue<SpaceToken>;
  mr?: ResponsiveValue<SpaceToken>;
  mb?: ResponsiveValue<SpaceToken>;
  ml?: ResponsiveValue<SpaceToken>;
  p?: ResponsiveValue<SpaceToken>;
  px?: ResponsiveValue<SpaceToken>;
  py?: ResponsiveValue<SpaceToken>;
  pt?: ResponsiveValue<SpaceToken>;
  pr?: ResponsiveValue<SpaceToken>;
  pb?: ResponsiveValue<SpaceToken>;
  pl?: ResponsiveValue<SpaceToken>;

  w?: ResponsiveValue<'full' | 'screen' | 'auto' | number>;
  h?: ResponsiveValue<'full' | 'screen' | 'auto' | number>;
  minW?: ResponsiveValue<number>;
  minH?: ResponsiveValue<number>;
  maxW?: ResponsiveValue<number>;
  maxH?: ResponsiveValue<number>;

  fontSize?: ResponsiveValue<FontSize>;
  fontWeight?: ResponsiveValue<FontWeight>;
  fontFamily?: FontFamily;
  textAlign?: ResponsiveValue<'left' | 'center' | 'right' | 'justify'>;
  lineHeight?: ResponsiveValue<'none' | 'tight' | 'normal' | 'relaxed'>;
  truncate?: boolean;

  textColor?: ColorName;
  colorIntensity?: ColorIntensity;
  bg?: {
    color?: ColorName;
    intensity?: ColorIntensity;
    variant?:
      | 'default'
      | 'primary'
      | 'secondary'
      | 'tertiary'
      | 'inverse'
      | 'elevation';
    state?: 'base' | 'weak' | 'strong';
  };

  border?: boolean | 'top' | 'right' | 'bottom' | 'left';
  borderColor?: ColorName;
  borderIntensity?: ColorIntensity;
  rounding?: RoundingToken;

  elevation?: 'accent' | 'floating' | 'raised';
  opacity?: 0 | 100 | 200 | 300 | 400 | 500;
  shadow?: 'sm' | 'md' | 'lg' | 'xl';
  blur?: 'sm' | 'md' | 'lg';

  hover?: {
    scale?: boolean;
    opacity?: number;
    bg?: string;
    shadow?: string;
  };
  active?: {
    scale?: boolean;
    opacity?: number;
    bg?: string;
  };
  focus?: {
    ring?: boolean;
    ringColor?: ColorName;
    ringOffset?: number;
  };
  disabled?: boolean;

  status?: {
    variant: StatusVariant;
    state?: StatusState;
  };

  animate?: {
    duration?: number;
    delay?: number;
    ease?: 'linear' | 'ease-in' | 'ease-out' | 'ease-in-out';
  };

  zIndex?: number;
  order?: number;
  overflow?: 'hidden' | 'scroll' | 'auto' | 'visible';

  hidden?: boolean;

  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  transparent?: boolean;
  size?: 1 | 2 | 3;
  className?: ClassValue;
  red?: boolean;
  fullWidth?: boolean;
};

export function resolveResponsiveValue<T>(
  value: ResponsiveValue<T>,
  resolver: (val: T) => string,
): string[] {
  if (value && typeof value === 'object') {
    return Object.entries(value).map(
      ([breakpoint, val]) => `${breakpoint}:${resolver(val)}`,
    );
  }
  return [resolver(value)];
}

export function variantsProps<T extends object>(
  name: string,
  props: T & VariantProps,
  ...additionalClasses: ClassValue[]
): Omit<T, keyof VariantProps> & { className: string } {
  const {
    display,
    position,
    direction,
    justify,
    align,
    wrap,
    gap,
    column,
    col = column,
    row,
    m,
    mx,
    my,
    mt,
    mr,
    mb,
    ml,
    p,
    px,
    py,
    pt,
    pr,
    pb,
    pl,
    w,
    h,
    minW,
    minH,
    maxW,
    maxH,
    fontSize,
    fontWeight,
    fontFamily,
    textAlign,
    lineHeight,
    truncate,
    textColor,
    colorIntensity,
    bg,
    border,
    borderColor,
    borderIntensity,
    rounding,
    elevation,
    opacity,
    shadow,
    blur,
    hover,
    active,
    focus,
    disabled,
    status,
    animate,
    zIndex,
    order,
    overflow,
    primary,
    secondary,
    tertiary,
    transparent,
    size,
    className,
    fullWidth,
    red,
    hidden,
    ariaLabel,
    ...others
  } = props;

  function hasValue<T>(value: T | undefined): value is T {
    return value !== undefined;
  }

  const classes = cx(
    'zc',
    name,
    className,
    { hidden },

    // Layout & Display
    hasValue(display) && resolveResponsiveValue(display, (d) => `d-${d}`),
    hasValue(position) &&
      resolveResponsiveValue(position, (p) => `position-${p}`),

    // Flex & Grid
    hasValue(direction) &&
      resolveResponsiveValue(direction, (d) => `flex-${d}`),
    hasValue(justify) && resolveResponsiveValue(justify, (j) => `justify-${j}`),
    hasValue(align) && resolveResponsiveValue(align, (a) => `items-${a}`),
    hasValue(wrap) &&
      resolveResponsiveValue(wrap, (w) => (w ? 'flex-wrap' : 'flex-nowrap')),
    hasValue(gap) && resolveResponsiveValue(gap, (g) => `gap-${g}`),
    hasValue(col) && resolveResponsiveValue(col, (c) => (c ? 'col' : '')),
    hasValue(row) && resolveResponsiveValue(row, (r) => (r ? 'row' : '')),

    // Margins
    hasValue(m) && resolveResponsiveValue(m, (margin) => `m-${margin}`),
    hasValue(mx) && resolveResponsiveValue(mx, (margin) => `mx-${margin}`),
    hasValue(my) && resolveResponsiveValue(my, (margin) => `my-${margin}`),
    hasValue(mt) && resolveResponsiveValue(mt, (margin) => `mt-${margin}`),
    hasValue(mr) && resolveResponsiveValue(mr, (margin) => `mr-${margin}`),
    hasValue(mb) && resolveResponsiveValue(mb, (margin) => `mb-${margin}`),
    hasValue(ml) && resolveResponsiveValue(ml, (margin) => `ml-${margin}`),

    // Paddings
    hasValue(p) && resolveResponsiveValue(p, (padding) => `p-${padding}`),
    hasValue(px) && resolveResponsiveValue(px, (padding) => `px-${padding}`),
    hasValue(py) && resolveResponsiveValue(py, (padding) => `py-${padding}`),
    hasValue(pt) && resolveResponsiveValue(pt, (padding) => `pt-${padding}`),
    hasValue(pr) && resolveResponsiveValue(pr, (padding) => `pr-${padding}`),
    hasValue(pb) && resolveResponsiveValue(pb, (padding) => `pb-${padding}`),
    hasValue(pl) && resolveResponsiveValue(pl, (padding) => `pl-${padding}`),

    // Dimensions
    hasValue(w) &&
      resolveResponsiveValue(w, (width) =>
        typeof width === 'number' ? `w-${width}` : `w-${width}`,
      ),
    hasValue(h) &&
      resolveResponsiveValue(h, (height) =>
        typeof height === 'number' ? `h-${height}` : `h-${height}`,
      ),
    hasValue(minW) && resolveResponsiveValue(minW, (width) => `min-w-${width}`),
    hasValue(minH) &&
      resolveResponsiveValue(minH, (height) => `min-h-${height}`),
    hasValue(maxW) && resolveResponsiveValue(maxW, (width) => `max-w-${width}`),
    hasValue(maxH) &&
      resolveResponsiveValue(maxH, (height) => `max-h-${height}`),

    // Typography
    hasValue(fontSize) &&
      resolveResponsiveValue(fontSize, (fs) => `text-${fs}`),
    hasValue(fontWeight) &&
      resolveResponsiveValue(fontWeight, (fw) => `font-${fw}`),
    fontFamily && `font-${fontFamily}`,
    hasValue(textAlign) &&
      resolveResponsiveValue(textAlign, (ta) => `text-${ta}`),
    hasValue(lineHeight) &&
      resolveResponsiveValue(lineHeight, (lh) => `leading-${lh}`),
    truncate && 'truncate',

    // Colors & Background
    hasValue(textColor) &&
      hasValue(colorIntensity) &&
      `text-${textColor}-${colorIntensity}`,

    bg && {
      ...(hasValue(bg.color) && hasValue(bg.intensity)
        ? { [`bg-${bg.color}-${bg.intensity}`]: true }
        : {}),
      ...(bg.variant
        ? { [`bg-${bg.variant}-${bg.state || 'base'}`]: true }
        : {}),
    },

    // Borders
    hasValue(border) &&
      (typeof border === 'boolean' ? 'border' : `border-${border}`),
    hasValue(borderColor) &&
      hasValue(borderIntensity) &&
      `border-${borderColor}-${borderIntensity}`,
    hasValue(rounding) && `rounding-${rounding}`,

    // Effects
    hasValue(elevation) && `elevation-${elevation}`,
    hasValue(opacity) && `opacity-${opacity}`,
    hasValue(shadow) && `shadow-${shadow}`,
    hasValue(blur) && `blur-${blur}`,

    // Interactive States
    hover && {
      'hover:scale': hover.scale,
      [`hover:opacity-${hover.opacity}`]: hasValue(hover.opacity),
      [`hover:bg-${hover.bg}`]: hover.bg,
      [`hover:shadow-${hover.shadow}`]: hover.shadow,
    },

    active && {
      'active:scale': active.scale,
      [`active:opacity-${active.opacity}`]: hasValue(active.opacity),
      [`active:bg-${active.bg}`]: active.bg,
    },

    focus && {
      'focus:ring': focus.ring,
      [`focus:ring-${focus.ringColor}`]: focus.ringColor,
      [`focus:ring-offset-${focus.ringOffset}`]: hasValue(focus.ringOffset),
    },

    disabled && 'disabled',

    // Status
    status && `status-${status.variant}-${status.state || 'base'}`,

    // Animation
    animate && {
      [`duration-${animate.duration}`]: hasValue(animate.duration),
      [`delay-${animate.delay}`]: hasValue(animate.delay),
      [`ease-${animate.ease}`]: animate.ease,
    },

    // Misc
    hasValue(zIndex) && `z-${zIndex}`,
    hasValue(order) && `order-${order}`,
    hasValue(overflow) && `overflow-${overflow}`,

    hasValue(primary) &&
      resolveResponsiveValue(primary, (p) => (p ? 'primary' : '')),
    hasValue(secondary) &&
      resolveResponsiveValue(secondary, (s) => (s ? 'secondary' : '')),
    hasValue(tertiary) &&
      resolveResponsiveValue(tertiary, (t) => (t ? 'tertiary' : '')),
    hasValue(transparent) &&
      resolveResponsiveValue(transparent, (t) => (t ? 'transparent' : '')),
    hasValue(size) && resolveResponsiveValue(size, (s) => `size-${s}`),
    hasValue(red) && resolveResponsiveValue(red, (r) => (r ? 'red' : '')),
    hasValue(fullWidth) &&
      resolveResponsiveValue(fullWidth, (f) => (f ? 'fullWidth' : '')),

    ...additionalClasses,
  );

  return {
    ...others,
    className: classes,
  };
}

export type WithVariant<T> = T extends (props: infer Props) => infer Result
  ? Props extends { className?: any }
    ? (
        props: {
          [K in keyof _Merge<Props, VariantProps>]: _Merge<
            Props,
            VariantProps
          >[K] extends unknown
            ? _Merge<Props, VariantProps>[K]
            : never;
        } & {},
      ) => Result
    : T
  : T;

export function withVariant<T>(name: string, component: T): WithVariant<T> {
  const Element = React.forwardRef(function Element(props: any, ref) {
    return (
      <>
        {React.createElement(
          /* @ts-ignore */
          component,
          variantsProps(name, {
            'aria-label':
              typeof props.ariaLabel === 'string' ? props.ariaLabel : undefined,
            ...props,
            ref,
          }),
        )}
      </>
    );
  });

  Object.defineProperties(Element, {
    name: { value: name },
    displayName: { value: name },
  });

  // @ts-expect-error
  return Element;
}

export function withVariantForBadlyTypedComponents<T>(
  name: string,
  component: T,
): T {
  return withVariant(name, component) as T;
}

export type _Merge<A, B> = ({
  [K in keyof B]: B[K] extends unknown ? B[K] : never;
} & {}) &
  ({
    [K in keyof A as K extends keyof B ? never : K]: A[K] extends unknown
      ? A[K]
      : never;
  } & {});
