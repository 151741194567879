import { IS_BROWSER } from 'powership';

export type IntersectionCallback = (
  inView: boolean,
  intersectionRatio: number,
  entry: IntersectionObserverEntry,
) => void;

const elements = new Map<Node, IntersectionCallback>();

const INTERSECTION_OBSERVER = (() => {
  if (IS_BROWSER) {
    try {
      const intersectionObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          // While it would be nice if you could just look at isIntersecting to determine if the component is inside the viewport, browsers can't agree on how to use it.
          // -Firefox ignores `threshold` when considering `isIntersecting`, so it will never be false again if `threshold` is > 0
          const inView = entry.isIntersecting;

          // @ts-ignore support IntersectionObserver v2
          if (typeof entry.isVisible === 'undefined') {
            // The browser doesn't support Intersection Observer v2, falling back to v1 behavior.
            // @ts-ignore
            entry.isVisible = inView;
          }

          try {
            elements.get(entry.target)?.(
              inView,
              entry.intersectionRatio,
              entry,
            );
          } catch (e) {
            console.error(e);
          }
        });
      });

      // const mutationObserver = new MutationObserver(function (mutations) {
      //   mutations.forEach(function (mutation) {
      //     mutation.removedNodes.forEach((element) => {
      //       elements.delete(element);
      //     });
      //   });
      // });
      // mutationObserver.observe(document.body, {
      //   subtree: true,
      //   childList: true,
      // });

      return intersectionObserver;
    } catch (e) {
      console.error(e);
    }
  }
})();

export function observeIntersection(
  element: Element,
  cb: IntersectionCallback,
) {
  elements.set(element, cb);
  INTERSECTION_OBSERVER?.observe(element);

  return () => {
    elements.delete(element);
    INTERSECTION_OBSERVER!.unobserve(element);
  };
}
