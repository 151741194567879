import Big from 'big.js';
import { groupBy, keyBy } from 'powership';
import { appConfig } from '~/app-config.tsx';
import { ProductVariant } from '~/lib/shopify/types.ts';
import type { CartData } from '~/methods/loadCartData.ts';
import type { CartState, CartStateItem } from '~/state';

type PartialCartState = Omit<
  CartState,
  'error' | 'loading' | 'lua' | 'layout' | 'checkoutState' | 'paymentStatus'
> & {
  priceObject: Big;
};

export function parseCartState(
  input: Omit<CartData, 'paymentStatus'>,
): PartialCartState {
  const { HARD_LIMIT_QUANTITY } = appConfig;
  const { cartLines, products, zipCode, deliveryAddress } = input;
  const productById = keyBy(products, (el) => el.id);

  let errors: string[] = [];
  let cartItems: CartStateItem[] = [];

  let count = 0;

  cartLines.forEach((line) => {
    let { productId, quantity, variantId } = line;

    if (quantity > HARD_LIMIT_QUANTITY) {
      quantity = HARD_LIMIT_QUANTITY;
    }

    let product = productById[productId];
    if (!product) {
      return errors.push(`Product "${line.productId}" not included.`);
    }
    let variant = product.variants.find((el) => el.id === variantId);
    if (!variant) {
      return errors.push(`Variant "${variantId}" not included.`);
    }
    quantity = sanitizeQuantity({ selectedVariant: variant, quantity });
    if (!quantity) return;

    const amount = Big(variant.price.amount).times(quantity).toString();

    if (!(+amount >= 0)) {
      return errors.push(
        `Variant "${variantId}" has an invalid price: ${amount}.`,
      );
    }

    count += quantity;

    cartItems.push({
      ...variant,
      variantTotalPrice: {
        currencyCode: variant.price.currencyCode,
        amount,
      },
      product,
      quantity,
      productId,
    });
  });

  let priceObject = new Big('0');

  let currencyCode = '';
  cartItems.forEach((el) => {
    const { variantTotalPrice, availableForSale, quantity } = el;
    const { amount } = variantTotalPrice;
    if (!availableForSale || !(quantity > 0) || !(+amount >= 0)) return;
    currencyCode = variantTotalPrice.currencyCode;
    priceObject = priceObject.add(amount);
  });

  let canCheckout = priceObject.gt(0);

  const next: PartialCartState = {
    deliveryAddress,
    zipCode,
    priceObject,
    productById,
    byVariantId: keyBy(cartItems, (el) => el.id),
    byProductId: groupBy(cartItems, (el) => el.productId),
    cartItems,
    count,
    canCheckout,
    price: {
      currencyCode,
      amount: priceObject.toString(),
    },
  };

  Object.defineProperties(next, {
    priceObject: {
      get() {
        return priceObject;
      },
      enumerable: false,
    },
  });

  return next;
}

function sanitizeQuantity(init: {
  selectedVariant: ProductVariant;
  quantity: number;
}) {
  const { selectedVariant, quantity } = init;
  const { currentlyNotInStock, quantityAvailable } = selectedVariant;

  let isAboveMaxQuantity = currentlyNotInStock // <- true if you can continue selling without stock
    ? false
    : quantity > quantityAvailable;

  return isAboveMaxQuantity ? quantityAvailable : quantity;
}
