import './CartFooterActions.scss';

import { cx, ElementProps } from '@zazcart/commons';
import { Button, Link } from '@zazcart/ui';
import * as React from 'react';

export type CartFooterActionsProps = ElementProps<{}, 'div'>;

export function CartFooterActions(props: CartFooterActionsProps) {
  const { className } = props;

  return (
    <div className={cx('CartFooterActions', className)}>
      <Link href={'/checkout'}>
        <Button primary fullWidth accessibilityLabel="Checkout">
          Checkout
        </Button>
      </Link>
    </div>
  );
}
