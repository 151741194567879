let methods = Object.create(null);

const proxy = new Proxy(Object.create(null), {
  get(_, key: string) {
    return (methods[key] = methods[key] || createMethod(key));
  },
});

function createMethod(method: string) {
  function clientMethod(args: {}, headers = {}) {
    return fetch('/methods', {
      method: 'POST',
      headers: {
        ...headers,
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        method,
        args,
      }),
    }).then((result) => {
      const content = result.headers.get('content-type');
      if (content?.match('application/json')) {
        return result.json();
      }
      return result.text();
    });
  }

  Object.defineProperties(clientMethod, {
    name: { value: `${method}_client` },
  });

  return clientMethod;
}

export default proxy;
