import '~/css/index.scss';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PRODUCTION, UserAgent } from '@zazcart/commons';
import { DeviceTypeProvider } from '@zazcart/ui';
import { ms } from 'powership';
import { PropsWithChildren, default as React, useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import { IsomorphicAppConfig } from '~/app-config.tsx';
import { MethodContext } from '~/core/method-context.ts';
import { StateProvider } from '~/state';

export type INSTATE = {
  url: string;
  cookies: { [K: string]: any };
  userAgent: UserAgent | undefined;
  queryState?: object | undefined;
  appConfig: IsomorphicAppConfig;
  deviceType: 'desktop' | 'mobile';
};

export type ManifestAssets = {
  js: string[];
  css: string[];
};

export type AppProps = {
  productionAssets: null | ManifestAssets;
  ssrMethodsContext: MethodContext | null; // only ssr
  initialState: INSTATE;
  clientRef: { current?: QueryClient };
};

const RootPropsContext = React.createContext(null as unknown as AppProps);

const TWO_MINUTES = ms('2min');

// This wrapper should only have providers,
// as expected by tanstack router Wrapper config.
// We use the HTMLTemplate as the root, and it is used in __root.tsx
export function RootDataProvider(
  props: PropsWithChildren<{ rootProps: AppProps }>,
) {
  const { children, rootProps } = props;
  const { initialState, ssrMethodsContext, clientRef } = rootProps;

  const [queryClient] = useState(() => {
    if (clientRef.current) return clientRef.current;
    const client = new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: TWO_MINUTES,
        },
      },
    });

    return (clientRef.current = client);
  });

  return (
    <RootPropsContext.Provider value={rootProps}>
      <QueryClientProvider client={queryClient}>
        <CookiesProvider
          defaultSetOptions={ssrMethodsContext?.settings.cookieOptions}
          allCookies={initialState.cookies}
        >
          <DeviceTypeProvider deviceType={initialState.deviceType}>
            <StateProvider ssrMethodsContext={ssrMethodsContext}>
              {children}
            </StateProvider>
          </DeviceTypeProvider>
        </CookiesProvider>
      </QueryClientProvider>
    </RootPropsContext.Provider>
  );
}

export function HTMLTemplate(props: PropsWithChildren<AppProps>) {
  const { children, productionAssets } = props;

  const [stateScript] = useState(() => {
    return (
      <script
        dangerouslySetInnerHTML={{
          __html: `window.INSTATE=${JSON.stringify(props.initialState)}`,
        }}
      />
    );
  });

  return (
    <html lang={'pt-br'}>
      <head>
        <meta charSet="UTF-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />

        {iconsElement}

        {productionAssets?.css?.map((cssFile) => (
          <link
            key={cssFile}
            rel="stylesheet"
            /*@ts-ignore*/
            crossOrigin
            href={`/${cssFile}`}
          />
        ))}

        {productionAssets?.js?.map((js) => (
          <script
            /*@ts-ignore*/
            crossOrigin
            key={js}
            type="module"
            src={`/${js}`}
          />
        ))}

        <DevScriptsHead />
      </head>

      <body>
        <div id="root">{children}</div>

        {stateScript}

        <DevScriptsBody />
      </body>
    </html>
  );
}

function DevScriptsHead() {
  if (PRODUCTION) return null;
  const text = `
import RefreshRuntime from "/@react-refresh"
RefreshRuntime.injectIntoGlobalHook(window)
window.$RefreshReg$ = () => {}
window.$RefreshSig$ = () => (type) => type
window.__vite_plugin_react_preamble_installed__ = true
`;
  return (
    <>
      <script
        type="module"
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
      <script type="module" src="/@vite/client" />
    </>
  );
}

function DevScriptsBody() {
  if (PRODUCTION) return null;
  return <script type="module" src="/src/entry-client.tsx"></script>;
}

const iconsElement = (
  <>
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="/icons/apple-touch-icon.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="/icons/favicon-32x32.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="/icons/favicon-16x16.png"
    />

    <link rel="manifest" href="/icons/site.webmanifest" />
    <link rel="mask-icon" href="/icons/safari-pinned-tab.svg" color="#787878" />
    <link rel="shortcut icon" href="/icons/favicon.ico" />
    <meta name="msapplication-TileColor" content="#00a300" />
    <meta name="msapplication-config" content="/icons/browserconfig.xml" />
  </>
);
