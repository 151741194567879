import { Merge } from 'powership';

import { AddressSelection } from '~/components/AddressForm/interfaces.ts';
import { useMethod, useMethodClient } from '~/core/use-method';
import { parseCartState } from '~/lib/payment/parseCartState.ts';
import { Money, Product, ProductVariant } from '~/lib/shopify/types.ts';
import { createStore } from '~/state/createStore.tsx';
import { StateInit } from '~/state/state-interfaces.ts';

/**
 * ProductOptionWithVariants represents a product option + the variants where the
 * option is available.
 * A variant is a combination of some product options
 *  - for example "Color Blue + Size 32" is a possible variant for a T-Shirt
 * A product options are present in product variants
 *  - for example "Blue" is an option value for the T-Shirt color  Option.
 *    So "Color" is an `Option Name` and "Blue" is an `Option Value`.
 */

export type CartStateItem = ProductVariant & {
  quantity: number;
  productId: string;
  product: Product;
  variantTotalPrice: Money;
};

export type CartStateItemInput = Omit<CartStateItem, 'variantTotalPrice'>;

export type CartLayoutState = {
  showCart: boolean;
};

export type CartState = {
  byVariantId: Record<string, CartStateItem>;
  productById: Record<string, Product>;
  lua: number;
  count: number;
  price: Money;
  cartItems: CartStateItem[];
  loading: boolean;
  canCheckout: boolean;
  error: string | null;
  byProductId: Record<string, CartStateItem[]>;
  zipCode: string | null | undefined;
  deliveryAddress: AddressSelection | null | undefined;
  layout: CartLayoutState;
  checkoutState: 'idle' | 'loading' | 'error';
};

export type CartActions = {
  addItem: (cartItem: CartStateItemInput) => Promise<void>;
  setItem: (cartItem: CartStateItemInput) => Promise<void>;
  setZipCode: (zipCode: string) => Promise<void>;
  setOpen: (open: boolean) => void;
  toggleOpen: () => void;
  // redirectToCheckout: () => Promise<void>;
  syncCart: () => Promise<void>;
};

export type CartStore = Merge<CartState, CartActions>;

function updateCartState(state: CartState): CartState {
  const productById: CartState['productById'] = {};
  const items = Object.values(state.byVariantId);

  items.forEach((el) => (productById[el.product.id] = el.product));
  const products = Object.values(productById);

  const cartItems = items
    .filter((el) => el.quantity > 0)
    .map((el) => ({ ...el, variantId: el.id }));

  const { priceObject, ...next } = parseCartState({
    cartLines: cartItems,
    products,
    zipCode: state.zipCode,
    deliveryAddress: state.deliveryAddress,
  });

  return {
    ...state,
    ...next,
    lua: Date.now(),
    error: state.error,
    loading: state.loading,
  };
}

function cartInitialState(init: StateInit['cart']): CartState {
  const {
    cartItems,
    productById,
    price,
    byProductId,
    byVariantId,
    canCheckout,
    count,
    zipCode,
    deliveryAddress,
  } = parseCartState(init);

  return {
    deliveryAddress,
    layout: { showCart: false },
    productById,
    price,
    byProductId,
    byVariantId,
    canCheckout,
    count,
    error: null,
    loading: false,
    lua: Date.now(),
    cartItems,
    zipCode,
    checkoutState: 'idle',
  };
}

const creature = createStore('cart')
  .initialState(() => {
    const { data: cartData } = useMethod('loadCartData', {});
    return cartInitialState(cartData);
  })
  .methods((set, get) => {
    const upsertCartClient = useMethodClient('upsertCart');

    return {
      async addItem(cartItem: CartStateItemInput) {
        set((state) => {
          const { id, quantity } = cartItem;
          const currentQuantity = state.byVariantId[id]?.quantity || 0;
          const nextQuantity = currentQuantity + quantity;

          const newState = {
            ...state,
            layout: { ...state.layout, showCart: true },
            byVariantId: {
              ...state.byVariantId,
              [id]: {
                ...cartItem,
                variantTotalPrice: { currencyCode: '', amount: '' },
                quantity: nextQuantity,
              },
            },
          };

          return updateCartState(newState);
        });

        await this.syncCart();
      },

      toCartLines() {
        return get().cartItems.map(({ quantity, productId, id: variantId }) => {
          return {
            quantity,
            productId,
            variantId,
          };
        });
      },

      async setItem(cartItem: CartStateItemInput) {
        set((state) => {
          const { id } = cartItem;
          const newState = {
            ...state,
            byVariantId: {
              ...state.byVariantId,
              [id]: {
                ...cartItem,
                variantTotalPrice: { currencyCode: '', amount: '' },
              },
            },
          };

          return updateCartState(newState);
        });

        await this.syncCart();
      },

      async setZipCode(zipCode: string) {
        const currentZipCode = get().zipCode;
        if (currentZipCode !== zipCode) {
          set((state) => updateCartState({ ...state, zipCode }));
          await this.syncCart();
        }
      },

      setOpen: (open: boolean) =>
        set((state) => ({
          ...state,
          layout: { ...state.layout, showCart: open },
        })),

      toggleOpen: () =>
        set((state) => ({
          ...state,
          layout: { ...state.layout, showCart: !state.layout.showCart },
        })),

      syncCart: async () => {
        const { cartItems, zipCode } = get();
        await upsertCartClient.exec({
          zipCode,
          cartLines: cartItems.map(
            ({ productId, id: variantId, quantity }) => ({
              variantId,
              quantity,
              productId,
            }),
          ),
        });
      },
    };
  });

export const { CartProvider, useCartStore } = creature;

export function useShoppingCart() {
  return useCartStore((store) => store);
}
