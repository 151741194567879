import './Button.scss';

import React from 'react';

import { Button as BaseButton } from '@ariakit/react';

import { Merge } from 'powership';
import { VariantProps, variantsProps } from '~/variants.tsx';

export type ButtonProps = Merge<
  Parameters<typeof BaseButton>[0],
  Merge<
    VariantProps & {},
    {
      accessibilityLabel: string;
      checkout?: boolean;
      link?: boolean;
      href?: string;
      onClick?(
        event: React.SyntheticEvent<HTMLInputElement | HTMLButtonElement>,
      ): void;
    }
  >
>;

export function Button(props: ButtonProps) {
  const { link, checkout, accessibilityLabel, href, ...others } = props;

  const base = variantsProps(
    'Button',
    {
      ...others,
      'aria-label': accessibilityLabel || undefined,

      href,
    },
    { link, checkout, LinkButton: href !== undefined },
  );

  return href
    ? React.createElement('a', base)
    : React.createElement(BaseButton, base);
}
