import { useLayoutEffect } from 'react';

export function GlobalStyles(props: {
  styles: (string | boolean | null | undefined)[];
}) {
  const styles = props.styles.filter(Boolean).join(';');

  useLayoutEffect(() => {
    const style = document.createElement('style');
    style.innerText = `html > body { ${styles}; }`;
    document.body.appendChild(style);
    return () => {
      style.remove();
    };
  }, [styles]);

  return null;
}
