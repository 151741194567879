import { MethodName, MethodResult, Methods } from '~/core/interfaces.ts';
// @onlyServer
import { MethodContext } from '~/core/method-context.ts';
// @onlyServer
import * as methods from '../methods';
// @onlyBrowser
import client_methods from './client.tsx';

export function runMethod<Method extends MethodName>(
  method: Method,
  args: Methods[Method]['input'],
  context: MethodContext,
): Promise<MethodResult<Method>> {
  let result: any;

  // @onlyServer
  result = methods[method](
    // @ts-ignore
    args,
    // @ts-ignore
    context,
  );

  // @onlyBrowser
  result = client_methods[method](args);
  return result;
}

if (import.meta.env.DEV) {
  // @onlyServer
  (() => {
    if (typeof window !== 'undefined') {
      window.console.log(`we are not fine`);
    }
  })();
}
