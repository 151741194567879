import { jsonStringify, memoize, stringHash } from 'powership';

const stringify = memoize(jsonStringify);

export function shallowHash(value: unknown, prefix: number | string) {
  if (value && typeof value === 'object') {
    return objectShallowHash(value, prefix);
  }
  return objectShallowHash({ '': value }, prefix);
}

export function objectShallowHash<T extends object>(
  object: T,
  prefix: string | number,
) {
  if (!object || typeof object !== 'object') {
    throw new Error(
      `objectKVS: expected typeof input to object, found ${typeof object}`,
    );
  }
  let res = '';
  for (let k in object) {
    const type = typeof object[k];
    // prettier-ignore
    res = `${k}:${type}:${stringHash(`${object[k]}`)}` + (res ? `|${res}` : '');
  }
  return prefix + res;
}

/**
 * @deprecated
 * this export is just for DX, now; you know the right name is
 * `objectShallowHash`, because this is shallow
 */
export const objectHash = objectShallowHash;

export function jsonHash(query: unknown) {
  return stringify(query)[1];
}
