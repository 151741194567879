import { styled } from '@linaria/react';
import React, { ReactNode, useEffect, useRef, useTransition } from 'react';
import { useMethodPaginated } from '~/core/use-method.tsx';

import { PageInfo } from '@shopify/hydrogen-react/storefront-api-types';

import { Container } from '~/components/Container.tsx';
import {
  ProductCard,
  ProductCardMock,
  ProductsGrid,
} from '~/components/product-list/product-card.tsx';
import { MethodArgs, PaginatedMethodNames } from '~/core/interfaces.ts';
import { Product } from '~/lib/shopify/types.ts';

import { observeIntersection } from '@zazcart/commons';
import { Heading } from '@zazcart/ui';
import { Unsubscribe } from 'powership';
import { GlobalStyles } from '~/components/GlobalStyles.tsx';
import emptySearchImg from './assets/empty-search.webp';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: var(--gap);
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: var(--gap);

  h1.Heading {
    font-size: var(--font-size-400);
    text-align: center;
  }
`;

const EmptySearchResultText = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3);
  padding: var(--gap-3);

  h1 {
    font-weight: normal;
    font-size: var(--font-size-400);
    width: 100%;
  }

  img {
    width: min(70%, 400px);
    user-select: none;
    pointer-events: none;
    margin-left: 90px;
  }
`;

export type ProductListProps<Method extends PaginatedMethodNames> = {
  title: ReactNode;
  method: Method;
  variables: MethodArgs<Method>;
  description?: ReactNode;
  infinite?: boolean;
  className?: string;
};

export default function ProductList<Method extends PaginatedMethodNames>(
  props: ProductListProps<Method>,
) {
  const { title, description, infinite, variables, method, className } = props;
  const state = useMethodPaginated(method, variables);

  const { isLoading, data, fetchNextPage, isPending } = state;
  const { pages } = data;

  if (!isProductPages(pages)) return null;

  const search = (variables as { search: string | undefined }).search;
  const isEmptySearchResult = (() => {
    if (!search?.length) return false;
    return !isLoading && !pages?.[0].products.length;
  })();

  return (
    <Wrapper className={className}>
      {!!title && (
        <Title>
          <Heading lineClamp={2}>
            {title} <br />
            <small>{description}</small>
          </Heading>
        </Title>
      )}

      {isEmptySearchResult ? (
        <EmptySearchResultText>
          <GlobalStyles
            styles={[
              '--color-background-main-center:transparent!important',
              '--box-shadow-main-center:none!important',
            ]}
          />
          <h1>
            Nenhum resultado para <b>"{search}"</b>.
          </h1>
          <img src={emptySearchImg} alt={'empty search'} />
        </EmptySearchResultText>
      ) : null}

      <Container>
        <ProductsGrid>
          {pages
            .flat()
            .flatMap((page) => page!.products)
            .map((product) => {
              return (
                <ProductCard
                  key={product.id}
                  product={product}
                  cartItem={null}
                />
              );
            })}
        </ProductsGrid>
      </Container>

      {isLoading || (isPending && <ProductsLoaderIndicator />)}

      <Loader
        onChange={(atEnd) => {
          const lastPage = pages[pages.length - 1];
          const { hasNextPage, endCursor } = lastPage?.pageInfo || {};
          if (!atEnd || !infinite || !hasNextPage || isLoading || !endCursor) {
            return;
          }
          fetchNextPage().then(({ data }) => {
            const after = data?.pageParams?.[data.pageParams.length - 1] as
              | string
              | undefined;
          });
        }}
      />
    </Wrapper>
  );
}

function Loader(props: { onChange: (atEnd: boolean) => void }) {
  const { onChange } = props;
  const unsubscribe = useRef<Unsubscribe[]>([]);
  const [, start] = useTransition();
  useEffect(() => {
    return () => unsubscribe.current.forEach((el) => el());
  }, []);

  return (
    <div
      ref={(ref) => {
        if (!ref) return;
        unsubscribe.current.push(
          observeIntersection(ref, (inView) => {
            start(() => onChange(inView));
          }),
        );
      }}
    />
  );
}

function isProductPages(
  pages: any[],
): pages is { products: Product[]; pageInfo: PageInfo }[] {
  if (!pages.length) return true;
  return !!(pages[0].products && pages[0].pageInfo);
}

export function ProductsLoaderIndicator() {
  return (
    <Wrapper>
      <Container>
        <ProductsGrid>
          <ProductCardMock />
          <ProductCardMock />
          <ProductCardMock />
          <ProductCardMock />
          <ProductCardMock />
          <ProductCardMock />
          <ProductCardMock />
          <ProductCardMock />
        </ProductsGrid>
      </Container>
    </Wrapper>
  );
}
