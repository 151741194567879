import { styled } from '@linaria/react';
import { Spinner } from '@zazcart/gestalt';
import * as React from 'react';
import { Suspense } from 'react';
import { ErrorBoundary } from '~/components/ErrorBoundary.tsx';

export type LoadableProps = {
  children: React.ReactNode;
  loadingIndicator?: React.ReactNode;
  spinner?: boolean;
};

const LoadingIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 30vh;
`;

export function Loadable(props: LoadableProps) {
  const { children, loadingIndicator, spinner } = props;

  return (
    <ErrorBoundary>
      <Suspense
        fallback={(() => {
          if (loadingIndicator !== undefined) return loadingIndicator;
          if (spinner) {
            return (
              <LoadingIndicator>
                <Spinner show />
              </LoadingIndicator>
            );
          }
          return <Shimmer />;
        })()}
      >
        <>{children}</>
      </Suspense>
    </ErrorBoundary>
  );
}

const Shimmer = styled.div`
  width: 100%;
  height: 100%;
  animation: pulse 2000ms infinite;
`;
