import {
  create,
  useStore as useZustandStore,
  StoreApi as ZStoreApi,
} from 'zustand';
import { devtools } from 'zustand/middleware';

import { capitalize, Merge } from 'powership';
import React, { JSX, PropsWithChildren, useRef } from 'react';

export type ProviderProps = {
  children: React.ReactNode;
};

export type StoreMethodsCreator<State, Methods> = (
  set: ZStoreApi<State>['setState'],
  get: ZStoreApi<State>['getState'],
  store: React.MutableRefObject<ZStoreApi<State> | null>,
) => Methods;

export type StoreComponents<Name extends string, State, Methods> = {
  name: Name;
  Provider: React.FC<ProviderProps>;
  useStore: UseStoreHook<State, Methods>;
  wrap: WrapFunction<State, Methods>;
} & AutoGeneratedNames<Name, State, Methods>;

export type UseStoreHook<State, Methods> = {
  <T>(selector: (state: Merge<State, Methods>) => T): T;
  (): Merge<State, Methods>;
};

export type WrapFunction<State, Methods> = <Props = {}>(
  component: (props: Merge<Merge<State, Methods>, Props>) => React.ReactNode,
) => (props: Props) => JSX.Element;

export type AutoGeneratedNames<Name extends string, State, Methods> = {
  [K in `${Capitalize<Name>}Provider`]: React.FC<ProviderProps>;
} & {
  [K in `use${Capitalize<Name>}Store`]: UseStoreHook<State, Methods>;
};

function persist<T = any>(value: T, _name: string): T {
  return value;
}

export function createStore<State, Name extends string = string>(
  name: Name,
  useInitialState: State | (() => State),
): StoreComponents<
  Name,
  State,
  {
    set: ZStoreApi<State>['setState'];
    get: ZStoreApi<State>['getState'];
    store: React.MutableRefObject<ZStoreApi<State> | null>;
  }
>;

export function createStore<Name extends string>(
  name: Name,
): {
  initialState<State>(useInitialState: State | (() => State)): {
    methods<Methods>(
      useMethodsCreator: StoreMethodsCreator<State, Methods>,
    ): StoreComponents<Name, State, Omit<Methods, 'initialState'>>;
  };
};

export function createStore<Name extends string>(
  name: Name,
  ...args: any[]
): any {
  const self = {
    initialState<State extends object>(useInitialState: State | (() => State)) {
      return {
        methods<Methods>(
          useMethodsCreator: (
            set: ZStoreApi<State>['setState'],
            get: ZStoreApi<State>['getState'],
            store: React.MutableRefObject<ZStoreApi<State> | null>,
          ) => Methods,
        ) {
          type FullState = Merge<State, Omit<Methods, 'initialState'>>;
          type Store = ZStoreApi<FullState>;

          function useCreateStore(name: string): Store {
            const initialState =
              typeof useInitialState === 'function'
                ? useInitialState()
                : useInitialState;

            const ref = useRef<ZStoreApi<State> | null>(null);

            const methods = useMethodsCreator(
              (values) => ref.current!.setState(values),
              () => ref.current!.getState(),
              ref,
            );

            if (!ref.current) {
              /**
               * CREATING
               */
              ref.current = create<any>()(
                persist(
                  devtools(
                    () => {
                      return {
                        ...initialState,
                        ...methods,
                      };
                    },
                    { name },
                  ),
                  name,
                ),
              );
            }

            return ref.current as any;
          }

          const Context = React.createContext<Store>(null as any);

          const capitalized = capitalize(name);

          type ProviderName = `${Capitalize<Name>}Provider`;
          type HookName = `use${Capitalize<Name>}Store`;

          const hookName = `use${capitalized}Store` as HookName;
          const providerName = `${capitalized}Provider` as ProviderName;

          function useStore<T>(get: (state: FullState) => T): T;
          function useStore(): FullState;
          function useStore(selector?: any) {
            const store = React.useContext(Context);
            if (!store) {
              throw new Error(
                `${hookName} must be used within ${providerName}`,
              );
            }
            return useZustandStore(store, selector);
          }

          function Provider(props: PropsWithChildren) {
            const { children } = props;
            const store = useCreateStore(name);
            return (
              <Context.Provider value={store}>{children}</Context.Provider>
            );
          }

          function wrap(component: (props: FullState) => React.ReactNode) {
            function Wrapper(props: any) {
              const state: any = useStore();
              return React.createElement(component, { ...state, ...props });
            }

            function Render(props: any) {
              return (
                <Provider>
                  <Wrapper {...props} />
                </Provider>
              );
            }

            Wrapper.displayName = `${capitalized}Wrapper`;
            Render.displayName = `${capitalized}Render`;

            return Render;
          }

          type Result = {
            name: Name;
            Provider: typeof Provider;
            useStore: typeof useStore;
            wrap: typeof wrap;
          } & {
            [N in HookName]: typeof useStore;
          } & {
            [N in ProviderName]: typeof Provider;
          } extends infer R
            ? { [K in keyof R]: R[K] extends unknown ? R[K] : never } & {}
            : never;

          return {
            name,
            useStore,
            Provider,
            wrap,
            [providerName]: Provider,
            [hookName]: useStore,
          } as unknown as Result;
        },
      };
    },
  };

  if (args.length) {
    return self.initialState(args[0]).methods((set, get, store) => {
      return { set, get, store };
    });
  }

  return self;
}

export type StoreType<T> =
  T extends StoreComponents<any, any, any> ? ReturnType<T['useStore']> : never;
