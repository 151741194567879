import { hasProperty, keys, Unsubscribe } from 'powership';
import { DelayOptions, withDelay } from './withDelay';

export const IS_BROWSER = (() => {
  if (typeof document !== 'object') return false;
  return (
    typeof window !== 'undefined' &&
    typeof document?.body?.getClientRects === 'function'
  );
})();

export type DDListener =
  | ((values: DDRect) => void)
  | { current?: DDRect | null };

export type ElementInit =
  | (() => DDRect | HTMLElement | Element | null | undefined)
  | null
  | undefined
  | DDRect
  | HTMLElement
  | Element;

export type DDOptionsWithoutElement = DelayOptions;

export type DDOptions = DDOptionsWithoutElement & { element: ElementInit };

export type DDInit = ElementInit | DDOptions;

export type DDRect = {
  scrollWidth: number;
  scrollHeight: number;
  maxScrollTop: number;
  maxScrollLeft: number;
  naturalWidth: number;
  naturalHeight: number;
  scrollTop: number;
  scrollLeft: number;
  width: number;
  height: number;
  top: number;
  bottom: number;
  left: number;
  right: number;
  y: number;
  x: number;
  vertical: boolean;
  horizontal: boolean;
  square: boolean;
  ratio: number;
};

export class DOMDimensions implements DDRect {
  width!: number;
  scrollTop!: number;
  scrollLeft!: number;
  height!: number;
  top!: number;
  bottom!: number;
  left!: number;
  right!: number;
  y!: number;
  x!: number;
  scrollWidth!: number;
  scrollHeight!: number;
  maxScrollTop!: number;
  maxScrollLeft!: number;
  naturalWidth!: number;
  naturalHeight!: number;
  vertical!: boolean;
  horizontal!: boolean;
  square!: boolean;
  ratio!: number;
  readonly reference: { current: HTMLElement | null } = { current: null };
  private options: Partial<DDOptions> = {
    awaitLimit: 300,
    throttle: 50,
    requestAnimationFrame: true,
  };
  private _listeners = new Set<DDListener>();
  private _observer: ResizeObserver | null = null;
  private _dispatch = withDelay((rect: DDRect) => {
    // @ts-ignore
    for (let observer of this._listeners) {
      if (!observer) continue;

      if (typeof observer == 'object') {
        observer.current = rect;
        continue;
      }

      if (typeof observer === 'function') {
        observer(rect);
      }
    }
  }, this.options);

  constructor(init: DDInit, listener?: DDListener) {
    if (hasProperty(init, 'element')) {
      Object.assign(this.options, init);
      this.init(init.element, listener);
    } else {
      this.init(init, listener);
    }
  }

  static create = (init: DDInit, listener?: DDListener) => {
    return new DOMDimensions(init, listener);
  };

  setOptions = (options: Partial<DDOptionsWithoutElement>) => {
    Object.assign(this.options, options);
  };

  disconnect = () => {
    this._listeners.clear();
    this._observer?.disconnect();
  };

  /**
   * Returns a string with css variables with current dimensions
   */
  toString = (props: { prefix?: string } = {}) => {
    const { prefix = 'dimensions' } = props;
    const values = cleanDDRect(this);

    let res = '';

    keys(values).forEach((key) => {
      const value = values[key];
      if (typeof value !== 'number') return;
      res += `--${prefix}-${key}: ${value.toFixed(2)}px;\n`;
    });

    keys(values).forEach((key) => {
      const value = values[key];
      if (typeof value !== 'number') return;
      res += `--${prefix}-${key}-n: ${value.toFixed(2)};\n`;
    });

    return res;
  };

  init = (init: ElementInit, listener?: DDListener) => {
    const [element] = getElements(init);

    if (element === this.reference.current) {
      return this.disconnect;
    }

    this.reference.current = element;

    this._observer?.disconnect();
    this._dispatch.cancel();

    this.onChange(listener);

    if (this._observer) {
      this._observer.disconnect();
    } else {
      // -----------------
      // is not a re-init
      // ----------------
      const clean = getDDRect(init);
      Object.assign(this, clean);
      this._dispatch(clean);
    }

    if (element) {
      let onScroll = (ev: any) => {
        const rect = getDDRect(ev.target);
        Object.assign(this, rect);
        this._dispatch(rect);
      };

      element.addEventListener('scroll', onScroll);

      this._observer = new ResizeObserver(([item]) => {
        if (!item) return;
        const rect = getDDRect(item.target);
        Object.assign(this, rect);
        this._dispatch(rect);
      });

      this._observer.observe(element);
    }

    return this.disconnect;
  };

  onChange = (listener: DDListener | null | undefined): Unsubscribe => {
    if (!listener) return () => undefined;
    this._listeners.add(listener);
    return () => this._listeners.delete(listener);
  };

  addToHead = (props: { prefix: string } = { prefix: 'dimensions' }) => {
    if (!IS_BROWSER) return null;
    const { prefix } = props;

    const html = () => `:root{\n${DIMENSIONS.toString(props)}}`;

    const dimensionsTag = document.createElement('style');
    dimensionsTag.dataset['dimension_variables'] = prefix;
    document.head.appendChild(dimensionsTag);

    DIMENSIONS.onChange(() => {
      dimensionsTag.innerHTML = html();
    });

    dimensionsTag.innerHTML = html();

    return dimensionsTag;
  };

  relativeHeight = (input: { width: number }) => {
    const { width } = input;
    const { naturalHeight, naturalWidth } = this;
    return naturalHeight && naturalWidth
      ? (naturalHeight * width) / naturalWidth
      : 0;
  };

  relativeWidth = (input: { height: number }) => {
    const { height } = input;
    const { naturalHeight, naturalWidth } = this;
    return naturalHeight && naturalWidth
      ? (naturalWidth * height) / naturalHeight
      : 0;
  };
}

export function observeDimensions(element: any) {
  return DOMDimensions.create(element);
}

function getElements(init?: DDInit): [HTMLElement, DOMRect] | [null, null] {
  if (!IS_BROWSER) return [null, null];

  if (isHTMLElement(init)) return [init, init.getBoundingClientRect()];

  if (typeof init === 'function') {
    try {
      const element = init();
      if (isHTMLElement(element))
        return [element, element.getBoundingClientRect()];
    } catch (e: any) {}
  }

  return [null, null];
}

export function getDDRect(init?: DDInit): DDRect {
  const [element, rects] = getElements(init);

  if (!element) {
    return cleanDDRect();
  }

  const { width, height } = (() => {
    if (
      !element.clientWidth &&
      !element.clientHeight &&
      hasNaturalDimensions(element)
    ) {
      return { width: element.naturalWidth, height: element.naturalHeight };
    }
    return { width: element.clientWidth, height: element.clientHeight };
  })();

  const { naturalHeight, naturalWidth } = (() => {
    if (hasNaturalDimensions(element)) {
      return {
        naturalHeight: element.naturalHeight,
        naturalWidth: element.naturalWidth,
      };
    }

    return {
      naturalHeight: element.clientHeight,
      naturalWidth: element.clientWidth,
    };
  })();

  const ratio = width / height;
  const maxScrollTop = element.scrollHeight - element.clientHeight;
  const maxScrollLeft = element.scrollWidth - element.clientWidth;

  const vertical = height > width;
  const horizontal = height < width;
  const square = ratio === 1;

  const domRect: DOMRect = rects.toJSON();

  const scrollLeft = element.scrollLeft - (element.clientLeft || 0);
  const scrollTop = element.scrollTop - (element.clientTop || 0);

  return sealRect({
    ...domRect,
    scrollLeft,
    scrollTop,
    scrollHeight: element.scrollHeight,
    scrollWidth: element.scrollWidth,
    maxScrollTop,
    maxScrollLeft,
    vertical,
    horizontal,
    naturalHeight,
    naturalWidth,
    square,
    ratio,
  });
}

export function getScrollPosition(element: Element) {
  const left = element.scrollLeft - (element.clientLeft || 0);
  const top = element.scrollTop - (element.clientTop || 0);
  return { left, top };
}

export function isHTMLElement(input: any): input is HTMLElement {
  return typeof input?.getBoundingClientRect === 'function';
}

function defaultRect(): DDRect {
  return {
    vertical: false,
    horizontal: false,
    square: false,
    ratio: 0,
    width: 0,
    scrollTop: 0,
    scrollLeft: 0,
    height: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    y: 0,
    x: 0,
    maxScrollLeft: 0,
    maxScrollTop: 0,
    naturalHeight: 0,
    naturalWidth: 0,
    scrollHeight: 0,
    scrollWidth: 0,
  };
}

const rectKeys = new Set(keys(defaultRect()));

export function cleanDDRect(input?: DDRect): DDRect {
  // @ts-ignore
  if (input?._DDRECT_) return input;

  const rect = defaultRect();

  if (!input) return rect;

  rectKeys.forEach((k) => {
    const val = input[k];
    if (typeof val !== 'number') return;
    if (val > 0) {
      // @ts-ignore
      rect[k] = val;
    }
  });

  return sealRect(rect);
}

export const DIMENSIONS = new DOMDimensions(() => document.body);

if (IS_BROWSER) {
  window.__DIMENSIONS__ = DIMENSIONS;
}

declare global {
  interface Window {
    __DIMENSIONS__: DOMDimensions;
  }
}

let added = false;

export function createDimensionTag() {
  if (added || !IS_BROWSER) return;
  added = true;
  DIMENSIONS.addToHead();
  // createScrollHandler().init()
}

function sealRect(rect: DDRect) {
  Object.defineProperties(rect, {
    _DDRECT_: { value: true, enumerable: false },
  });
  return rect;
}

export function hasNaturalDimensions(
  element: any,
): element is { naturalHeight: number; naturalWidth: number } {
  return (
    typeof element?.naturalHeight === 'number' &&
    typeof element?.naturalWidth === 'number'
  );
}

// function createScrollHandler() {
//   const element = document.body
//   let lastPos = getScrollPosition(element).top
//   let scrollingCycleDistance = 0
//   let lastDirection = 'idle' as 'idle' | 'down' | 'up'
//
//   return {
//     init() {
//       function _onScroll() {
//         const scrollPos = getScrollPosition(element).top + window.scrollY
//         const maxScroll = 100
//
//         function getValue() {
//           /**
//            * Handling scrolling cycle
//            */
//           {
//             // is scrolling down
//             if (scrollPos > lastPos) {
//               if (lastDirection === 'down') {
//                 // if direction is the same as previous
//                 scrollingCycleDistance += 1
//               } else {
//                 scrollingCycleDistance = 0
//               }
//               lastDirection = 'down'
//             } else {
//               // scrolling up
//               if (lastDirection === 'up') {
//                 // if direction is the same as previous
//                 scrollingCycleDistance += 1
//               } else {
//                 scrollingCycleDistance = 0
//               }
//               lastDirection = 'up'
//             }
//             lastPos = scrollPos
//           }
//
//           return (() => {
//             // if scrolled up for more than N distance, we can consider
//             // the user is starting going to the page top
//             if (lastDirection === 'up' && scrollingCycleDistance > 1) {
//               let distance = Math.min(scrollingCycleDistance * 4, 100)
//               return Math.min(1, 1 - distance / 100)
//             }
//
//             if (scrollPos <= maxScroll) {
//               return scrollPos / maxScroll
//             }
//
//             return 1
//           })()
//         }
//
//         let started = scrollPos > 2
//         // const value = started ? getValue() : 0
//         // const easedValue = easing.inOutSine(value)
//
//         // document.body.style.setProperty('--scroll-top-100', `${value}`)
//         // document.body.style.setProperty(
//         //   '--scroll-top-100-ease',
//         //   `${easedValue}`
//         // )
//         document.body.style.setProperty(
//           '--scroll-started',
//           `${started ? 1 : 0}`
//         )
//         // document.body.style.setProperty(
//         //   '--scroll-top-100-inverse-ease',
//         //   `${started ? 1 - easedValue : 0}`
//         // )
//       }
//
//       const onScroll = withDelay(_onScroll, {
//         requestAnimationFrame: true,
//         throttle: 10,
//         awaitLimit: 100,
//       })
//       document.body.addEventListener('scroll', _onScroll)
//       document.addEventListener('scroll', _onScroll)
//       onScroll()
//     },
//   }
// }

// // handling the div main-content width
// const setAppDimensions = () => {
//   if (!IS_BROWSER) return
//   // @ts-ignore
//   const mainContentWidth = window['main-content']?.getBoundingClientRect().width
//   document.body.style.setProperty(
//     '--main-content-width',
//     `${mainContentWidth}px`
//   )
// }
// setAppDimensions()
// DIMENSIONS.onChange(setAppDimensions)
