'use client';

import { styled } from '@linaria/react';
import { cx } from '@zazcart/commons';
import * as React from 'react';

const ContainerStyles = styled.div`
  width: 100%;
  max-width: var(--center-container-width);
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 1023px) {
    padding-left: var(--gap);
    padding-right: var(--gap);
  }
`;

export function Container(props: ContainerProps) {
  const { className } = props;

  return (
    <ContainerStyles className={cx('Container', className)}>
      {props.children}
    </ContainerStyles>
  );
}

export type ContainerProps = {
  className?: string;
  children?: React.ReactNode;
};
