import { styled } from '@linaria/react';
import { cx } from '@zazcart/commons';
import { TapArea } from '@zazcart/gestalt';
import { IconButton, Link } from '@zazcart/ui';
import React, { useState } from 'react';
import { CartButton } from '~/components/cart-button.tsx';
import { GlobalStyles } from '~/components/GlobalStyles.tsx';
import { Logo } from '~/components/logo.tsx';
import MenuSidebar from '~/components/sidebar/menu-sidebar.tsx';
import { MainSearch } from './MainSearch.tsx';

const Wrapper = styled.header`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: sticky;
  display: flex;
  width: 100%;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: center;
    //background: linear-gradient(
    //  180deg,
    //  var(--color-background-box-default) 0%,
    //  var(--color-background-box-default) 50%,
    //  var(--color-background-box-elevation-accent) 50%,
    //  var(--color-background-box-elevation-accent) 100%
    //);
  }
`;

const MainContainer = styled.div`
  --desktop-logo-width: 200px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--nav-height);
  padding: 0 10px 0;
  will-change: var(--page-not-scrolled);
  transition: all 200ms;
  //background-color: var(--color-background-box-elevation-accent);

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    column-gap: var(--gap);
    border-radius: var(--nav-rounding);
    padding: var(--gap) 0;
    margin-top: var(--gap);
  }
`;

const FirstRowMobileOnly = styled.div`
  @media screen and (min-width: 1024px) {
    // mobile only
    display: none;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 53px;
  .left {
    display: flex;
    align-items: center;

    > button:nth-child(2) {
      opacity: 0;
      pointer-events: none;
    }
  }

  .logo-link {
    display: flex;
    height: 40px;
    align-items: center;

    .Logo {
      display: block;
      height: 28px;
      margin-top: -2px;
    }
  }

  .mobile-first-row-right {
    display: flex;
    a {
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media screen and (min-width: 1024px) {
      .Icon {
        width: 28px;
        height: 28px;
      }
    }
  }
`;

const SecondRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .MainSearch {
    width: 100%;
  }

  .left,
  .right {
    // MOBILE HIDDEN
    @media screen and (max-width: 1023px) {
      display: none;
    }
  }

  @media screen and (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 9px;

    .MainSearch {
      width: calc(100% - 96px);
    }
  }
`;

export function MainHeader({ className }: { className?: string }) {
  const [showSidebar, setShowSidebar] = useState(true);

  return (
    <Wrapper className={cx('MainHeader', className)}>
      <MainContainer>
        <FirstRowMobileOnly>
          <div className="left">
            <MenuSidebar />
          </div>

          <Link href="/" className={'logo-link'}>
            <TapArea>
              <Logo className={'mobile-header-logo'} />
            </TapArea>
          </Link>

          <div className={'mobile-first-row-right'}>
            <CartButton />
          </div>
        </FirstRowMobileOnly>

        <SecondRow>
          <div className="left">
            {!showSidebar && (
              <GlobalStyles
                styles={['#left-sidebar {opacity:0;pointer:events:none;}']}
              />
            )}
            <IconButton
              accessibilityLabel={'open menu'}
              icon={'menu'}
              onClick={() => {
                setShowSidebar((show) => !show);
              }}
            />
          </div>

          <MainSearch />

          <div className="right">
            <CartButton />
          </div>
        </SecondRow>
      </MainContainer>
    </Wrapper>
  );
}
