// @ts-nocheck
import React, { ReactNode } from 'react';

import threeSixty from './360.svg?react';
import threeDMove from './3d-move.svg?react';
import threeD from './3d.svg?react';
import accessibility from './accessibility.svg?react';
import adGroup from './ad-group.svg?react';
import ad from './ad.svg?react';
import addCircle from './add-circle.svg?react';
import addLayout from './add-layout.svg?react';
import addPin from './add-pin.svg?react';
import addSection from './add-section.svg?react';
import add from './add.svg?react';
import adsOverview from './ads-overview.svg?react';
import adsStats from './ads-stats.svg?react';
import alert from './alert.svg?react';
import alignBottomCenter from './align-bottom-center.svg?react';
import alignBottomLeft from './align-bottom-left.svg?react';
import alignBottomRight from './align-bottom-right.svg?react';
import alignBottom from './align-bottom.svg?react';
import alignMiddle from './align-middle.svg?react';
import alignTopCenter from './align-top-center.svg?react';
import alignTopLeft from './align-top-left.svg?react';
import alignTopRight from './align-top-right.svg?react';
import alignTop from './align-top.svg?react';
import alphabetical from './alphabetical.svg?react';
import androidShare from './android-share.svg?react';
import angledPin from './angled-pin.svg?react';
import animate from './animate.svg?react';
import api from './api.svg?react';
import apple from './apple.svg?react';
import apps from './apps.svg?react';
import arTryOn from './ar-try-on.svg?react';
import arrowBack from './arrow-back.svg?react';
import arrowCircleBack from './arrow-circle-back.svg?react';
import arrowCircleDown from './arrow-circle-down.svg?react';
import arrowCircleForward from './arrow-circle-forward.svg?react';
import arrowCircleUp from './arrow-circle-up.svg?react';
import arrowCounterClockwise from './arrow-counter-clockwise.svg?react';
import arrowDoubleBack from './arrow-double-back.svg?react';
import arrowDoubleForward from './arrow-double-forward.svg?react';
import arrowDown from './arrow-down.svg?react';
import arrowEnd from './arrow-end.svg?react';
import arrowForward from './arrow-forward.svg?react';
import arrowLeftCurved from './arrow-left-curved.svg?react';
import arrowNested from './arrow-nested.svg?react';
import arrowStart from './arrow-start.svg?react';
import arrowUpLeft from './arrow-up-left.svg?react';
import arrowUpRight from './arrow-up-right.svg?react';
import arrowUp from './arrow-up.svg?react';
import arrowsVertical from './arrows-vertical.svg?react';
import aspectRatio from './aspect-ratio.svg?react';
import audioBars from './audio-bars.svg?react';
import audioMix from './audio-mix.svg?react';
import audioPlaying from './audio-playing.svg?react';
import bellFill from './bell-fill.svg?react';
import bell from './bell.svg?react';
import birthdayCake from './birthday-cake.svg?react';
import boardStickerLarge from './board-sticker-large.svg?react';
import boardStickerSmall from './board-sticker-small.svg?react';
import board from './board.svg?react';
import borderShadow from './border-shadow.svg?react';
import border from './border.svg?react';
import briefcase from './briefcase.svg?react';
import buildingBusiness from './building-business.svg?react';
import businessHierarchy from './business-hierarchy.svg?react';
import calendarCheck from './calendar-check.svg?react';
import calendar from './calendar.svg?react';
import cameraFlip from './camera-flip.svg?react';
import cameraRoll from './camera-roll.svg?react';
import camera from './camera.svg?react';
import cancel from './cancel.svg?react';
import canonicalPin from './canonical-pin.svg?react';
import captionsOutline from './captions-outline.svg?react';
import captions from './captions.svg?react';
import checkCircleFill from './check-circle-fill.svg?react';
import checkCircle from './check-circle.svg?react';
import check from './check.svg?react';
import chevronUpCircle from './chevron-up-circle.svg?react';
import circleEllipsis from './circle-ellipsis.svg?react';
import circleInformationFill from './circle-information-fill.svg?react';
import circleInitializing from './circle-initializing.svg?react';
import circle from './circle.svg?react';
import clear from './clear.svg?react';
import clips from './clips.svg?react';
import clockChecked from './clock-checked.svg?react';
import clock from './clock.svg?react';
import cloudServer from './cloud-server.svg?react';
import codeCheck from './code-check.svg?react';
import code from './code.svg?react';
import cog from './cog.svg?react';
import collage from './collage.svg?react';
import collapse from './collapse.svg?react';
import colorPalette from './color-palette.svg?react';
import colorPicker from './color-picker.svg?react';
import colorSolid from './color-solid.svg?react';
import colorSplit from './color-split.svg?react';
import color from './color.svg?react';
import compass from './compass.svg?react';
import compose from './compose.svg?react';
import contrast from './contrast.svg?react';
import conversionTag from './conversion-tag.svg?react';
import copyToClipboard from './copy-to-clipboard.svg?react';
import creditCard from './credit-card.svg?react';
import crop from './crop.svg?react';
import cutout from './cutout.svg?react';
import dash from './dash.svg?react';
import dataSource from './data-source.svg?react';
import design from './design.svg?react';
import desktop from './desktop.svg?react';
import diagnostics from './diagnostics.svg?react';
import directionalArrowLeft from './directional-arrow-left.svg?react';
import directionalArrowRight from './directional-arrow-right.svg?react';
import download from './download.svg?react';
import dragDrop from './drag-drop.svg?react';
import draw from './draw.svg?react';
import dropbox from './dropbox.svg?react';
import duplicate from './duplicate.svg?react';
import edit from './edit.svg?react';
import ellipsis from './ellipsis.svg?react';
import envelope from './envelope.svg?react';
import eraseAuto from './erase-auto.svg?react';
import erase from './erase.svg?react';
import etsy from './etsy.svg?react';
import expand from './expand.svg?react';
import eyeHide from './eye-hide.svg?react';
import eye from './eye.svg?react';
import faceHappy from './face-happy.svg?react';
import faceNeutral from './face-neutral.svg?react';
import faceSad from './face-sad.svg?react';
import faceSmileyOutline from './face-smiley-outline.svg?react';
import faceSmiley from './face-smiley.svg?react';
import faceTryon from './face-tryon.svg?react';
import facebook from './facebook.svg?react';
import fade from './fade.svg?react';
import fileBox from './file-box.svg?react';
import fileUnknown from './file-unknown.svg?react';
import fillOpaque from './fill-opaque.svg?react';
import fillTransparent from './fill-transparent.svg?react';
import filter from './filter.svg?react';
import flag from './flag.svg?react';
import flame from './flame.svg?react';
import flashAutomatic from './flash-automatic.svg?react';
import flashSlash from './flash-slash.svg?react';
import flash from './flash.svg?react';
import flashlight from './flashlight.svg?react';
import flipHorizontal from './flip-horizontal.svg?react';
import flipVertical from './flip-vertical.svg?react';
import folder from './folder.svg?react';
import forward from './forward.svg?react';
import gauge from './gauge.svg?react';
import ghost from './ghost.svg?react';
import gifVisual from './gif-visual.svg?react';
import gif from './gif.svg?react';
import globeChecked from './globe-checked.svg?react';
import globe from './globe.svg?react';
import gmail from './gmail.svg?react';
import graphBar from './graph-bar.svg?react';
import graphPie from './graph-pie.svg?react';
import handPointing from './hand-pointing.svg?react';
import hand from './hand.svg?react';
import handle from './handle.svg?react';
import heartBroken from './heart-broken.svg?react';
import heartHealth from './heart-health.svg?react';
import heartOutline from './heart-outline.svg?react';
import heart from './heart.svg?react';
import highlights from './highlights.svg?react';
import history from './history.svg?react';
import homeFill from './home-fill.svg?react';
import home from './home.svg?react';
import ideaPin from './idea-pin.svg?react';
import imagePortrait from './image-portrait.svg?react';
import impressum from './impressum.svg?react';
import infoCircle from './info-circle.svg?react';
import information from './information.svg?react';
import insightsAudience from './insights-audience.svg?react';
import insightsConversion from './insights-conversion.svg?react';
import instagram from './instagram.svg?react';
import invoice from './invoice.svg?react';
import key from './key.svg?react';
import knoop from './knoop.svg?react';
import labFlask from './lab-flask.svg?react';
import lassoMagic from './lasso-magic.svg?react';
import layout from './layout.svg?react';
import lightbulb from './lightbulb.svg?react';
import lightningBoltCircle from './lightning-bolt-circle.svg?react';
import linkBroken from './link-broken.svg?react';
import link from './link.svg?react';
import lips from './lips.svg?react';
import location from './location.svg?react';
import lock from './lock.svg?react';
import logoLarge from './logo-large.svg?react';
import logoSmall from './logo-small.svg?react';
import logout from './logout.svg?react';
import magicPen from './magic-pen.svg?react';
import magicWand from './magic-wand.svg?react';
import manageAccess from './manage-access.svg?react';
import marginsLarge from './margins-large.svg?react';
import marginsMedium from './margins-medium.svg?react';
import marginsSmall from './margins-small.svg?react';
import maximize from './maximize.svg?react';
import megaphone from './megaphone.svg?react';
import menu from './menu.svg?react';
import messenger from './messenger.svg?react';
import microphone from './microphone.svg?react';
import minimize from './minimize.svg?react';
import mobile from './mobile.svg?react';
import moon from './moon.svg?react';
import movePin from './move-pin.svg?react';
import musicOff from './music-off.svg?react';
import musicOn from './music-on.svg?react';
import muteFill from './mute-fill.svg?react';
import mute from './mute.svg?react';
import orientationLandscape from './orientation-landscape.svg?react';
import orientationPortrait from './orientation-portrait.svg?react';
import outlook from './outlook.svg?react';
import overlayTextOutline from './overlay-text-outline.svg?react';
import overlayText from './overlay-text.svg?react';
import overview from './overview.svg?react';
import paperClip from './paper-clip.svg?react';
import paperLinesPlus from './paper-lines-plus.svg?react';
import pause from './pause.svg?react';
import penAuto from './pen-auto.svg?react';
import pen from './pen.svg?react';
import people from './people.svg?react';
import performancePlus from './performance-plus.svg?react';
import personAdd from './person-add.svg?react';
import personFill from './person-fill.svg?react';
import personMagnifyingGlass from './person-magnifying-glass.svg?react';
import person from './person.svg?react';
import phone from './phone.svg?react';
import pinHide from './pin-hide.svg?react';
import pin from './pin.svg?react';
import pinterest from './pinterest.svg?react';
import pix from './pix.svg?react';
import play from './play.svg?react';
import protect from './protect.svg?react';
import questionMark from './question-mark.svg?react';
import recordLimit10 from './record-limit-10.svg?react';
import recordLimit15 from './record-limit-15.svg?react';
import recordLimit30 from './record-limit-30.svg?react';
import recordLimit5 from './record-limit-5.svg?react';
import recordLimit60 from './record-limit-60.svg?react';
import recordLimit from './record-limit.svg?react';
import redo from './redo.svg?react';
import refresh from './refresh.svg?react';
import remove from './remove.svg?react';
import reorderImages from './reorder-images.svg?react';
import replace from './replace.svg?react';
import report from './report.svg?react';
import rewind from './rewind.svg?react';
import rocketship from './rocketship.svg?react';
import rotate from './rotate.svg?react';
import saturation from './saturation.svg?react';
import saveOutline from './save-outline.svg?react';
import saved from './saved.svg?react';
import scale from './scale.svg?react';
import scissors from './scissors.svg?react';
import searchByImage from './search-by-image.svg?react';
import searchFill from './search-fill.svg?react';
import search from './search.svg?react';
import security from './security.svg?react';
import select from './select.svg?react';
import send from './send.svg?react';
import shadows from './shadows.svg?react';
import shapes from './shapes.svg?react';
import share from './share.svg?react';
import shoppingBag from './shopping-bag.svg?react';
import shrink from './shrink.svg?react';
import shuffles from './shuffles.svg?react';
import skintone from './skintone.svg?react';
import slide from './slide.svg?react';
import smiley from './smiley.svg?react';
import sortAscending from './sort-ascending.svg?react';
import sortDescending from './sort-descending.svg?react';
import soundFill from './sound-fill.svg?react';
import soundSlashFill from './sound-slash-fill.svg?react';
import soundSlash from './sound-slash.svg?react';
import sound from './sound.svg?react';
import sparkle from './sparkle.svg?react';
import speechEllipsisFill from './speech-ellipsis-fill.svg?react';
import speechEllipsis from './speech-ellipsis.svg?react';
import speechExclamationPoint from './speech-exclamation-point.svg?react';
import speechHeart from './speech-heart.svg?react';
import speechOutline from './speech-outline.svg?react';
import speech from './speech.svg?react';
import speedThirdX from './speed-.3x.svg?react';
import speedFifthX from './speed-.5x.svg?react';
import speed1X from './speed-1x.svg?react';
import speedDoubleX from './speed-2x.svg?react';
import speedTripleX from './speed-3x.svg?react';
import speed from './speed.svg?react';
import spinner from './spinner.svg?react';
import spread from './spread.svg?react';
import starHalf from './star-half.svg?react';
import starOutline from './star-outline.svg?react';
import star from './star.svg?react';
import stickerImage from './sticker-image.svg?react';
import sticker from './sticker.svg?react';
import sun from './sun.svg?react';
import swap from './swap.svg?react';
import switchAccount from './switch-account.svg?react';
import table from './table.svg?react';
import tag from './tag.svg?react';
import target from './target.svg?react';
import template from './template.svg?react';
import terms from './terms.svg?react';
import textAlignCenter from './text-align-center.svg?react';
import textAlignLeft from './text-align-left.svg?react';
import textAlignRight from './text-align-right.svg?react';
import textAllCaps from './text-all-caps.svg?react';
import textExtraSmall from './text-extra-small.svg?react';
import textLarge from './text-large.svg?react';
import textLineHeight from './text-line-height.svg?react';
import textMedium from './text-medium.svg?react';
import textSentenceCase from './text-sentence-case.svg?react';
import textSize from './text-size.svg?react';
import textSmall from './text-small.svg?react';
import textSpacing from './text-spacing.svg?react';
import textSticker from './text-sticker.svg?react';
import text from './text.svg?react';
import thumbsDown from './thumbs-down.svg?react';
import thumbsUp from './thumbs-up.svg?react';
import timer10 from './timer-10.svg?react';
import timer3 from './timer-3.svg?react';
import timer from './timer.svg?react';
import trashCan from './trash-can.svg?react';
import trending from './trending.svg?react';
import undo from './undo.svg?react';
import unlock from './unlock.svg?react';
import uploadFeed from './upload-feed.svg?react';
import videoAdvance10Seconds from './video-advance-10-seconds.svg?react';
import videoCamera from './video-camera.svg?react';
import videoRewind10Seconds from './video-rewind-10-seconds.svg?react';
import viewTypeDefault from './view-type-default.svg?react';
import viewTypeDense from './view-type-dense.svg?react';
import viewTypeList from './view-type-list.svg?react';
import viewTypeSparse from './view-type-sparse.svg?react';
import visit from './visit.svg?react';
import warmth from './warmth.svg?react';
import wave from './wave.svg?react';
import whatsapp from './whatsapp.svg?react';
import wifiNo from './wifi-no.svg?react';
import workflowStatusAll from './workflow-status-all.svg?react';
import workflowStatusCanceled from './workflow-status-canceled.svg?react';
import workflowStatusHalted from './workflow-status-halted.svg?react';
import workflowStatusInProgress from './workflow-status-in-progress.svg?react';
import workflowStatusOk from './workflow-status-ok.svg?react';
import workflowStatusProblem from './workflow-status-problem.svg?react';
import workflowStatusQueued from './workflow-status-queued.svg?react';
import workflowStatusUnstarted from './workflow-status-unstarted.svg?react';
import workflowStatusWarning from './workflow-status-warning.svg?react';
import xSocial from './x-social.svg?react';
import yahoo from './yahoo.svg?react';
import youtube from './youtube.svg?react';

import { MergeProps, VariantProps, variantsProps } from '~/utils';

const input = {
  spinner,
  pix,
  '360': threeSixty,
  '3d': threeD,
  '3d-move': threeDMove,
  accessibility: accessibility,
  ad: ad,
  'ad-group': adGroup,
  add: add,
  'add-circle': addCircle,
  'add-layout': addLayout,
  'add-pin': addPin,
  'add-section': addSection,
  'ads-overview': adsOverview,
  'ads-stats': adsStats,
  alert: alert,
  'align-bottom': alignBottom,
  'align-bottom-center': alignBottomCenter,
  'align-bottom-left': alignBottomLeft,
  'align-bottom-right': alignBottomRight,
  'align-middle': alignMiddle,
  'align-top': alignTop,
  'align-top-center': alignTopCenter,
  'align-top-left': alignTopLeft,
  'align-top-right': alignTopRight,
  alphabetical: alphabetical,
  'android-share': androidShare,
  'angled-pin': angledPin,
  animate: animate,
  api: api,
  apple: apple,
  apps: apps,
  'ar-try-on': arTryOn,
  'arrow-back': arrowBack,
  'arrow-circle-back': arrowCircleBack,
  'arrow-circle-down': arrowCircleDown,
  'arrow-circle-forward': arrowCircleForward,
  'arrow-circle-up': arrowCircleUp,
  'arrow-counter-clockwise': arrowCounterClockwise,
  'arrow-double-back': arrowDoubleBack,
  'arrow-double-forward': arrowDoubleForward,
  'arrow-down': arrowDown,
  'arrow-end': arrowEnd,
  'arrow-forward': arrowForward,
  'arrow-left-curved': arrowLeftCurved,
  'arrow-nested': arrowNested,
  'arrow-start': arrowStart,
  'arrow-up': arrowUp,
  'arrow-up-left': arrowUpLeft,
  'arrow-up-right': arrowUpRight,
  'arrows-vertical': arrowsVertical,
  'aspect-ratio': aspectRatio,
  'audio-bars': audioBars,
  'audio-mix': audioMix,
  'audio-playing': audioPlaying,
  bell: bell,
  'bell-fill': bellFill,
  'birthday-cake': birthdayCake,
  board: board,
  'board-sticker-large': boardStickerLarge,
  'board-sticker-small': boardStickerSmall,
  border: border,
  'border-shadow': borderShadow,
  briefcase: briefcase,
  'building-business': buildingBusiness,
  'business-hierarchy': businessHierarchy,
  calendar: calendar,
  'calendar-check': calendarCheck,
  camera: camera,
  'camera-flip': cameraFlip,
  'camera-roll': cameraRoll,
  cancel: cancel,
  close: cancel,
  'canonical-pin': canonicalPin,
  captions: captions,
  'captions-outline': captionsOutline,
  check: check,
  'check-circle': checkCircle,
  'check-circle-fill': checkCircleFill,
  'chevron-up-circle': chevronUpCircle,
  circle: circle,
  'circle-ellipsis': circleEllipsis,
  'circle-information-fill': circleInformationFill,
  'circle-initializing': circleInitializing,
  clear: clear,
  clips: clips,
  clock: clock,
  'clock-checked': clockChecked,
  'cloud-server': cloudServer,
  code: code,
  'code-check': codeCheck,
  cog: cog,
  collage: collage,
  collapse: collapse,
  color: color,
  'color-palette': colorPalette,
  'color-picker': colorPicker,
  'color-solid': colorSolid,
  'color-split': colorSplit,
  compass: compass,
  compose: compose,
  contrast: contrast,
  'conversion-tag': conversionTag,
  'copy-to-clipboard': copyToClipboard,
  'credit-card': creditCard,
  crop: crop,
  cutout: cutout,
  dash: dash,
  'data-source': dataSource,
  design: design,
  desktop: desktop,
  diagnostics: diagnostics,
  'directional-arrow-left': directionalArrowLeft,
  'directional-arrow-right': directionalArrowRight,
  download: download,
  'drag-drop': dragDrop,
  draw: draw,
  dropbox: dropbox,
  duplicate: duplicate,
  edit: edit,
  ellipsis: ellipsis,
  envelope: envelope,
  erase: erase,
  'erase-auto': eraseAuto,
  etsy: etsy,
  expand: expand,
  eye: eye,
  'eye-hide': eyeHide,
  'face-happy': faceHappy,
  'face-neutral': faceNeutral,
  'face-sad': faceSad,
  'face-smiley': faceSmiley,
  'face-smiley-outline': faceSmileyOutline,
  'face-tryon': faceTryon,
  facebook: facebook,
  fade: fade,
  'file-box': fileBox,
  'file-unknown': fileUnknown,
  'fill-opaque': fillOpaque,
  'fill-transparent': fillTransparent,
  filter: filter,
  flag: flag,
  flame: flame,
  flash: flash,
  'flash-automatic': flashAutomatic,
  'flash-slash': flashSlash,
  flashlight: flashlight,
  'flip-horizontal': flipHorizontal,
  'flip-vertical': flipVertical,
  folder: folder,
  forward: forward,
  gauge: gauge,
  ghost: ghost,
  gif: gif,
  'gif-visual': gifVisual,
  globe: globe,
  'globe-checked': globeChecked,
  gmail: gmail,
  'graph-bar': graphBar,
  'graph-pie': graphPie,
  hand: hand,
  'hand-pointing': handPointing,
  handle: handle,
  heart: heart,
  'heart-broken': heartBroken,
  'heart-health': heartHealth,
  'heart-outline': heartOutline,
  highlights: highlights,
  history: history,
  home: home,
  'home-fill': homeFill,
  'idea-pin': ideaPin,
  'image-portrait': imagePortrait,
  impressum: impressum,
  'info-circle': infoCircle,
  information: information,
  'insights-audience': insightsAudience,
  'insights-conversion': insightsConversion,
  instagram: instagram,
  invoice: invoice,
  key: key,
  knoop: knoop,
  'lab-flask': labFlask,
  'lasso-magic': lassoMagic,
  layout: layout,
  lightbulb: lightbulb,
  'lightning-bolt-circle': lightningBoltCircle,
  link: link,
  'link-broken': linkBroken,
  lips: lips,
  location: location,
  lock: lock,
  'logo-large': logoLarge,
  'logo-small': logoSmall,
  logout: logout,
  'magic-pen': magicPen,
  'magic-wand': magicWand,
  'manage-access': manageAccess,
  'margins-large': marginsLarge,
  'margins-medium': marginsMedium,
  'margins-small': marginsSmall,
  maximize: maximize,
  megaphone: megaphone,
  menu: menu,
  messenger: messenger,
  microphone: microphone,
  minimize: minimize,
  mobile: mobile,
  moon: moon,
  'move-pin': movePin,
  'music-off': musicOff,
  'music-on': musicOn,
  mute: mute,
  'mute-fill': muteFill,
  'orientation-landscape': orientationLandscape,
  'orientation-portrait': orientationPortrait,
  outlook: outlook,
  'overlay-text': overlayText,
  'overlay-text-outline': overlayTextOutline,
  overview: overview,
  'paper-clip': paperClip,
  'paper-lines-plus': paperLinesPlus,
  pause: pause,
  pen: pen,
  'pen-auto': penAuto,
  people: people,
  'performance-plus': performancePlus,
  person: person,
  'person-add': personAdd,
  'person-fill': personFill,
  'person-magnifying-glass': personMagnifyingGlass,
  phone: phone,
  pin: pin,
  'pin-hide': pinHide,
  pinterest: pinterest,
  play: play,
  protect: protect,
  'question-mark': questionMark,
  'record-limit': recordLimit,
  'record-limit-10': recordLimit10,
  'record-limit-15': recordLimit15,
  'record-limit-30': recordLimit30,
  'record-limit-5': recordLimit5,
  'record-limit-60': recordLimit60,
  redo: redo,
  refresh: refresh,
  remove: remove,
  'reorder-images': reorderImages,
  replace: replace,
  report: report,
  rewind: rewind,
  rocketship: rocketship,
  rotate: rotate,
  saturation: saturation,
  'save-outline': saveOutline,
  saved: saved,
  scale: scale,
  scissors: scissors,
  search: search,
  'search-by-image': searchByImage,
  'search-fill': searchFill,
  security: security,
  select: select,
  send: send,
  shadows: shadows,
  shapes: shapes,
  share: share,
  'shopping-bag': shoppingBag,
  shrink: shrink,
  shuffles: shuffles,
  skintone: skintone,
  slide: slide,
  smiley: smiley,
  'sort-ascending': sortAscending,
  'sort-descending': sortDescending,
  sound: sound,
  'sound-fill': soundFill,
  'sound-slash': soundSlash,
  'sound-slash-fill': soundSlashFill,
  sparkle: sparkle,
  speech: speech,
  'speech-ellipsis': speechEllipsis,
  'speech-ellipsis-fill': speechEllipsisFill,
  'speech-exclamation-point': speechExclamationPoint,
  'speech-heart': speechHeart,
  'speech-outline': speechOutline,
  speed: speed,
  'speed-.3x': speedThirdX,
  'speed-.5x': speedFifthX,
  'speed-1x': speed1X,
  'speed-2x': speedDoubleX,
  'speed-3x': speedTripleX,
  spread: spread,
  star: star,
  'star-half': starHalf,
  'star-outline': starOutline,
  sticker: sticker,
  'sticker-image': stickerImage,
  sun: sun,
  swap: swap,
  'switch-account': switchAccount,
  table: table,
  tag: tag,
  target: target,
  template: template,
  terms: terms,
  text: text,
  'text-align-center': textAlignCenter,
  'text-align-left': textAlignLeft,
  'text-align-right': textAlignRight,
  'text-all-caps': textAllCaps,
  'text-extra-small': textExtraSmall,
  'text-large': textLarge,
  'text-line-height': textLineHeight,
  'text-medium': textMedium,
  'text-sentence-case': textSentenceCase,
  'text-size': textSize,
  'text-small': textSmall,
  'text-spacing': textSpacing,
  'text-sticker': textSticker,
  'thumbs-down': thumbsDown,
  'thumbs-up': thumbsUp,
  timer: timer,
  'timer-10': timer10,
  'timer-3': timer3,
  'trash-can': trashCan,
  trending: trending,
  undo: undo,
  unlock: unlock,
  'upload-feed': uploadFeed,
  'video-advance-10-seconds': videoAdvance10Seconds,
  'video-camera': videoCamera,
  'video-rewind-10-seconds': videoRewind10Seconds,
  'view-type-default': viewTypeDefault,
  'view-type-dense': viewTypeDense,
  'view-type-list': viewTypeList,
  'view-type-sparse': viewTypeSparse,
  visit: visit,
  warmth: warmth,
  wave: wave,
  whatsapp: whatsapp,
  'wifi-no': wifiNo,
  'workflow-status-all': workflowStatusAll,
  'workflow-status-canceled': workflowStatusCanceled,
  'workflow-status-halted': workflowStatusHalted,
  'workflow-status-in-progress': workflowStatusInProgress,
  'workflow-status-ok': workflowStatusOk,
  'workflow-status-problem': workflowStatusProblem,
  'workflow-status-queued': workflowStatusQueued,
  'workflow-status-unstarted': workflowStatusUnstarted,
  'workflow-status-warning': workflowStatusWarning,
  'x-social': xSocial,
  yahoo: yahoo,
  youtube: youtube,
} as const;

export type IconComponent = (props: IconPropsBase) => ReactNode;

export type IconName = keyof typeof input extends infer R
  ? R extends string
    ? R
    : never
  : never;

export type Icons = {
  readonly [K in IconName]: IconComponent;
} & {};

export type IconSvgProps = MergeProps<
  VariantProps,
  {
    viewBox?: string;
    size?: number | string;
    width?: number | string;
    height?: number | string;
  }
>;

export const icons = Object.create(null) as Icons;

Object.entries(input).forEach(([k, v]) => {
  const icon = function Icon(props) {
    let { size, width, height, className, ...others } = props;

    width = width || size;
    height = height || size;

    return React.createElement(v, {
      width,
      height,
      viewBox: '0 0 24 24',
      ...variantsProps(`Icon_${k}`, others, className),
    });
  };

  Object.defineProperties(icon, {
    name: { value: `Icon_${k}` },
  });

  icons[k] = icon;
});

export const iconNames = Object.keys(icons) as IconName[];
