import { styled } from '@linaria/react';
import { cx } from '@zazcart/commons';
import Big from 'big.js';
import React, { useMemo } from 'react';
import { Money } from '~/lib/shopify/types.ts';

const PriceWrapper = styled.span`
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  line-height: 1;
  font-size: var(--font-size-500);
  color: var(--color-text-default);

  span {
    display: inline-flex;
  }

  .price-part {
    display: inline-flex;

    &.decimal {
      display: none;
    }

    &.fraction {
      margin-left: 1px;
      font-size: 70%;
    }

    &.currency {
      margin-top: 4px;
      font-size: 70%;
    }
  }
`;

export type PriceProps = {
  className?: string;
  value: Money;
} & React.ComponentProps<'span'>;

function PriceBase(props: PriceProps) {
  let { className, value, ...rest } = props;
  const { amount: maybeBig, currencyCode } = value;

  const { amount, amountText } = useMemo(() => {
    const amount = Big(maybeBig);
    return {
      amount,
      amountText: maybeBig.toString(),
    };
  }, [maybeBig]);

  const formattedPrice = useMemo(() => {
    return new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: 'narrowSymbol',
    }).format(amount.div(100).toNumber());
  }, [amount, currencyCode]);

  const parts = useMemo(() => {
    return new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: 'narrowSymbol',
    }).formatToParts(amount.div(100).toNumber());
  }, [amount, currencyCode]);

  return (
    <PriceWrapper
      itemProp="offers"
      itemScope
      itemType="https://schema.org/Offer"
      className={cx(className, 'Price')}
      {...rest}
    >
      <meta itemProp="priceCurrency" content={currencyCode} />
      <span itemProp="price" content={amountText}>
        {parts.map((part, index) => (
          <span
            key={index}
            className={`price-part ${part.type}`}
            aria-hidden="true"
          >
            {part.value}
          </span>
        ))}
      </span>
      <span className="sr-only">{formattedPrice}</span>
    </PriceWrapper>
  );
}

export function Price(props: PriceProps) {
  if (!props.value?.currencyCode || !props.value?.amount) {
    console.log(`price received an invalid value`, props.value);
    return '-';
  }
  return React.createElement(PriceBase, props);
}
