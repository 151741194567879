import { IncomingHttpHeaders } from 'node:http';
import { IS_BROWSER, ms, nonNullValues, watchable } from 'powership';
import { CookieSetOptions } from 'universal-cookie';

export type AppConfig = ReturnType<typeof _getServerConfig>;
export type IsomorphicAppConfig = ReturnType<typeof sharedAppConfig>;

let config: AppConfig;

export function getAppConfig() {
  return (config =
    config ||
    config ||
    // @onlyServer
    _getServerConfig() ||
    sharedAppConfig());
}

// frontend readable config
export function sharedAppConfig() {
  let {
    COMPANY_NAME,
    SITE_NAME,
    PUBLIC_SITE_DOMAIN,
    NODE_ENV,
    DEV = NODE_ENV === 'development',
    HARD_LIMIT_QUANTITY = '999',
    STRIPE_PUBLIC_KEY,
    CLOUDFLARE_TURNSTILE_PUBLIC_KEY,
  } = IS_BROWSER ? (window as any).INSTATE.appConfig : process.env;

  const PROD = !DEV;

  return nonNullValues({
    STRIPE_PUBLIC_KEY,
    HARD_LIMIT_QUANTITY: +HARD_LIMIT_QUANTITY,
    COMPANY_NAME,
    SITE_NAME,
    PUBLIC_SITE_DOMAIN,
    DEV,
    PROD,
    PUBLIC_SITE_URL: DEV
      ? 'http://localhost:3000'
      : `https://${PUBLIC_SITE_DOMAIN}`,
    CLOUDFLARE_TURNSTILE_PUBLIC_KEY,
  });
}

// @onlyServer
function _getServerConfig() {
  let {
    COMPANY_NAME,
    SITE_NAME,
    PUBLIC_SITE_DOMAIN,
    SHOPIFY_STORE_DOMAIN,
    SHOPIFY_REVALIDATION_SECRET,
    SHOPIFY_STOREFRONT_ACCESS_TOKEN,
    SK_SEC,
    STRIPE_PUBLIC_KEY,
    SPA_KEY,
    CEP,
    CORREIOS,
    KANGU_KEY,
    MONGO_URL,
    MELHOR_ENVIO,
    PAYPAL_CLIENT_ID,
    PAYPAL_CLIENT_SECRET,
    PAYPAL_CHECKOUT_URL,
    PAG_SEGURO_URL,
    PAG_SEGURO_URL_PIX,
    PAG_SEGURO_TOKEN,
    TWILIO_TOKEN,
    TWILIO_SID,
    TWILIO_VERIFY_SERVICE_ID,
    SMTP_SERVER,
    SMTP_USER,
    SMTP_PORT,
    SMTP_KEY,
    SENDGRID_TOKEN,
    NODE_ENV = 'production',
    URL_SIGN_TOKEN,
    CLOUDFLARE_TURNSTILE_SECRET,
  } = process.env;

  const DEV = NODE_ENV === 'development';

  const sharedConfig = sharedAppConfig();

  const SHOPIFY_GRAPHQL_API_ENDPOINT = '/api/2024-07/graphql.json';

  SHOPIFY_STORE_DOMAIN = SHOPIFY_STORE_DOMAIN
    ? ensureStartsWith(SHOPIFY_STORE_DOMAIN, 'https://')
    : '';

  const SHOPIFY_API_URL = `${SHOPIFY_STORE_DOMAIN}${SHOPIFY_GRAPHQL_API_ENDPOINT}`;

  const requiredEnvironmentVariables = nonNullValues({
    ...sharedConfig,
    sharedConfig,
    MONGO_URL,
    URL_SIGN_TOKEN,
    SHOPIFY_API_URL,
    COMPANY_NAME,
    SITE_NAME,
    PUBLIC_SITE_DOMAIN,
    SHOPIFY_STORE_DOMAIN,
    SHOPIFY_REVALIDATION_SECRET,
    SHOPIFY_STOREFRONT_ACCESS_TOKEN,
    SK_SEC,
    STRIPE_PUBLIC_KEY,
    SPA_KEY,
    CEP,
    CORREIOS,
    KANGU_KEY,
    NODE_ENV,
    DEV,
    MELHOR_ENVIO,
    PAYPAL_CLIENT_ID,
    PAYPAL_CLIENT_SECRET,
    PAYPAL_CHECKOUT_URL,
    PAG_SEGURO_URL,
    PAG_SEGURO_URL_PIX,
    PAG_SEGURO_TOKEN,
    TWILIO_TOKEN,
    TWILIO_SID,
    TWILIO_VERIFY_SERVICE_ID,
    SMTP_SERVER,
    SMTP_USER,
    SMTP_PORT,
    SMTP_KEY,
    SENDGRID_TOKEN,
    CLOUDFLARE_TURNSTILE_SECRET,
  });

  const missingEnvironmentVariables = Object.entries(
    requiredEnvironmentVariables,
  ).filter(([k, v]) => {
    return v !== undefined ? null : k;
  });

  if (missingEnvironmentVariables.length) {
    throw new Error(
      `The following environment variables are missing. ${missingEnvironmentVariables}'\n'
      )}\n`,
    );
  }

  if (
    SHOPIFY_STORE_DOMAIN?.includes('[') ||
    SHOPIFY_STORE_DOMAIN?.includes(']')
  ) {
    throw new Error(
      'Your `SHOPIFY_STORE_DOMAIN` environment variable includes brackets (ie. `[` and / or `]`). Your site will not work with them there. Please remove them.',
    );
  }

  return requiredEnvironmentVariables;
}

export const appConfig = watchable(() => getAppConfig());

const ensureStartsWith = (stringToCheck: string, startsWith: string) =>
  stringToCheck.startsWith(startsWith)
    ? stringToCheck
    : `${startsWith}${stringToCheck}`;

export type RuntimeAppContextSettingsInput = {
  headers: IncomingHttpHeaders;
};

export type RuntimeAppContextSettings = ReturnType<
  typeof runtimeAppContextSettings
>;

const domains = [
  'zazcart.com',
  'zazcart.com.br',
  'zazcard.com.br',
  'localhost',
].map((mainDomain) => {
  return new RegExp(`^(.+\\.)?${mainDomain.replace('.', '\\.')}$`, 'i');
});

function isValidZazcartDomain(hostname: string): boolean {
  return domains.some((el) => el.test(hostname));
}

function getDomainFromHeader(header: string | undefined): string | null {
  if (!header) return null;
  try {
    const url = new URL(header);
    if (isValidZazcartDomain(url.hostname)) return url.hostname;
    return null;
  } catch {
    return null;
  }
}

export function runtimeAppContextSettings(
  params: RuntimeAppContextSettingsInput,
) {
  const { headers } = params;
  const { DEV } = appConfig;

  const domain = (() => {
    const originDomain = getDomainFromHeader(headers.origin);
    const refererDomain = getDomainFromHeader(headers.referer);
    return (
      originDomain || refererDomain || new URL(config.PUBLIC_SITE_URL).hostname
    );
  })();

  const isSecure = headers['x-forwarded-proto'] === 'https' || !DEV;

  const cookieOptions: CookieSetOptions = {
    domain: domain === 'localhost' ? undefined : `.${domain}`,
    httpOnly: true,
    path: '/',
    sameSite: 'lax',
    secure: isSecure,
    expires: new Date(Date.now() + ms('1Y')),
  };

  const csrfToken = headers['x-csrf-token'] as string | undefined;

  return {
    cookieOptions,
    domain,
    isSecure,
    csrfToken,
    userAgent: headers['user-agent'],
    clientIp: headers['x-forwarded-for'] || headers['x-real-ip'],
    isBot: /bot|crawler|spider|crawling/i.test(headers['user-agent'] || ''),
    environment: DEV ? 'development' : 'production',
  };
}
