import { default as React, ReactNode } from 'react';
import { MethodContext } from '~/core/method-context.ts';
import { CartProvider } from '~/state/cart.tsx';

export const SSRMethodContextProvider =
  React.createContext<MethodContext | null>(null);

export function StateProvider(props: StateProviderProps) {
  const { children, ssrMethodsContext } = props;

  return (
    <SSRMethodContextProvider.Provider value={ssrMethodsContext}>
      <CartProvider>{children}</CartProvider>
    </SSRMethodContextProvider.Provider>
  );
}

export type StateProviderProps = {
  children: ReactNode;
  ssrMethodsContext: MethodContext | null;
};
