'use client';

import { styled } from '@linaria/react';
import { cx } from '@zazcart/commons';
import * as React from 'react';

const Svg = styled.svg`
  color: var(--color-icon-subtle);
  padding-top: 2px;
`;

export function IconShoppingCart(props: IconShoppingCartProps) {
  const { className } = props;

  return (
    <Svg
      width={24}
      height={24}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      className={cx(className, 'icon-shopping-cart')}
    >
      <path
        fill={'currentColor'}
        d="M2.5,6.72A1.88,1.88,0,0,1,4.39,4.83H8a4.4,4.4,0,0,1,4,2.52H44.35a3.15,3.15,0,0,1,3,4l-3.23,12a5.68,5.68,0,0,1-5.48,4.2H16l.43,2.25a1.88,1.88,0,0,0,1.85,1.53H41a1.9,1.9,0,0,1,0,3.79H18.23a5.68,5.68,0,0,1-5.57-4.61L8.6,9.12A.63.63,0,0,0,8,8.61H4.39A1.88,1.88,0,0,1,2.5,6.72ZM12.59,41.39a3.78,3.78,0,1,1,3.78,3.78,3.78,3.78,0,0,1-3.78-3.78Zm26.47-3.78a3.78,3.78,0,0,1,0,7.56h0a3.78,3.78,0,0,1,0-7.56Z"
      />
    </Svg>
  );
}

export type IconShoppingCartProps = {
  className?: string;
  children?: React.ReactNode;
};
