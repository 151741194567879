import { throttle } from 'lodash-es';
import React, {
  Context,
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
  useTransition,
} from 'react';

const defaultDeviceType = 'mobile';

export type DeviceType = 'desktop' | 'mobile';

const DeviceTypeContext: Context<DeviceType> =
  createContext<DeviceType>(defaultDeviceType);

export type DeviceTypeProviderProps = {
  children: ReactNode;
  deviceType: DeviceType;
};

export function DeviceTypeProvider(props: DeviceTypeProviderProps) {
  const { children } = props;

  const [deviceType, setDeviceType] = useState(props.deviceType);
  const [, startTransition] = useTransition();

  useEffect(() => {
    const handle = throttle(function handle() {
      const asDeviceType = window.innerWidth < 1024 ? 'mobile' : 'desktop';
      if (deviceType !== asDeviceType) {
        startTransition(() => {
          setDeviceType(asDeviceType);
        });
      }
    });
    handle();
    document.addEventListener('resize', handle);
    return () => {
      document.removeEventListener('resize', handle);
    };
  }, [setDeviceType, startTransition]);

  return (
    <DeviceTypeContext.Provider value={deviceType}>
      {children}
    </DeviceTypeContext.Provider>
  );
}

export function useDeviceType(): DeviceType {
  return useContext(DeviceTypeContext) ?? defaultDeviceType;
}

export function useIsMobile() {
  return useDeviceType() === 'mobile';
}

export function useIsDesktop() {
  return useDeviceType() === 'desktop';
}

export const used = useIsDesktop;
export const usem = useIsMobile;
