import { usePath } from '@zazcart/commons';
import { useEffect } from 'react';

export function RestoreScroll() {
  const pathname = usePath();

  useEffect(() => {
    window.document.body.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
