import { BrowserHistory } from 'history';
import { sortedUniq } from 'lodash-es';
import {
  captureStackTrace,
  createProxy,
  IS_BROWSER,
  RouteUtils,
} from 'powership';
import * as React from 'react';
import {
  PropsWithChildren,
  ReactNode,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  AppRoutesConfig,
  createPath,
  RouteKey,
  RouteMatch,
  RouteParameters,
  RoutesConfig,
  UIRouterInstance,
} from './createRouter.ts';

export type RouterProps<Routes extends RoutesConfig = AppRoutesConfig> = {
  renderLoading?: () => ReactNode;
  url: string;
  router: UIRouterInstance<Routes>;
};

export type FlatRouteState = {
  url: string;
  search: string;
  searchObject: Record<string, string | undefined>;
  pathname: string;
  hash: string;
  key: string;
  state: unknown;
  subscriptionHash: string;
};

export type RouterContextValue<Routes extends RoutesConfig = AppRoutesConfig> =
  {
    router: UIRouterInstance<Routes>;
    match: RouteMatch;
  };

const RouterContext = React.createContext<RouterContextValue>(
  // @ts-expect-error
  null,
);

export type RouteSubscriptionKey = 'pathname' | 'search' | 'hash';

function hashLocation(values: Partial<Location>, keys: RouteSubscriptionKey[]) {
  keys = [...keys, 'pathname']; // PATH is always observed
  return sortedUniq(keys)
    .map((key) => {
      return values[key];
    })
    .join('/\\');
}

export function useHistorySubscription(
  $history: BrowserHistory,
  immutableSubscription: RouteSubscriptionKey[],
) {
  const [subscription] = useState(immutableSubscription);

  const [subscriptionHash, setSubscriptionHash] = useState(() =>
    hashLocation($history.location, subscription),
  );

  const hashRef = useRef(subscriptionHash);

  useEffect(() => {
    const unsubscribe = $history.listen((update) => {
      const newHash = hashLocation(update.location, subscription);
      if (newHash === hashRef.current) return;
      hashRef.current = newHash;
      setSubscriptionHash(newHash);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  function current() {
    const { pathname, search, hash, key, state } = $history.location;
    const url = createPath({ pathname, hash, search });
    const qs = RouteUtils.parseQueryString(search) as Record<string, string>;
    const searchObject = createProxy(() => qs, {
      onGet(key) {
        if (key === 'toString') return (() => search) as unknown as string;
        return typeof qs[key] === 'string' ? qs[key] : null;
      },
    });

    const flat: FlatRouteState = {
      url,
      search,
      pathname,
      hash,
      key,
      state,
      searchObject,
      subscriptionHash,
    };

    return {
      ...flat,
      current,
    };
  }

  return useMemo(() => {
    return current();
  }, [subscriptionHash]);
}

type ExpectedAny = any;

export function UIRouter<Routes extends RoutesConfig = AppRoutesConfig>(
  props: PropsWithChildren<RouterProps<Routes>>,
) {
  const { router, url, renderLoading } = props;

  useState(() => {
    if (IS_BROWSER) return;
    router.$history.push(url);
  });

  const { pathname } = useHistorySubscription(router.$history, ['pathname']);

  const value = useMemo((): RouterContextValue<Routes> => {
    return {
      router,
      match: router.$findRoute(pathname),
    };
  }, [pathname, router]);

  const loadingIndicator = renderLoading
    ? React.createElement(renderLoading)
    : null;

  return (
    <Suspense fallback={loadingIndicator}>
      <RouterContext.Provider value={value as ExpectedAny}>
        {props.children}
      </RouterContext.Provider>
    </Suspense>
  );
}

export type Strictness = 'strict' | 'not_strict';

export class RouteParamsMismatchError extends Error {}

export function useParams<Route extends RouteKey>(
  route: Route,
): RouteParameters<Route>;

export function useParams<Route extends RouteKey>(
  route: Route,
  strictness: 'not_strict',
): Partial<RouteParameters<Route>>;

export function useParams(): Record<string, string | undefined>;

export function useParams(route?: string, strictness: Strictness = 'strict') {
  const context = useRouterContext();
  const { match } = useRouter(['pathname']);

  return useMemo(() => {
    if (!route) return match?.params || {};
    const expectedPath = context.router.$config[route]?.path;
    if (!expectedPath || !match?.path || match.path !== expectedPath) {
      if (strictness === 'not_strict') return {};
      const error = new RouteParamsMismatchError(
        `The expected route path for ${route} is ${expectedPath}. The route match path is ${match?.path}`,
      );
      captureStackTrace(error, useParams);
      throw error;
    }
    return match.params;
  }, [route, match]);
}

export function useRouterContext() {
  const router = useContext(RouterContext);
  if (!router) throw new Error('Router context is missing.');
  return router;
}

export function useRouter(immutableSubscription: RouteSubscriptionKey[]) {
  const context = useRouterContext();

  const location = useHistorySubscription(
    context.router.$history,
    immutableSubscription,
  );

  return useMemo(() => {
    return {
      ...location,
      match: context.match,
    };
  }, [context.match?.params, context.match, location.pathname]);
}

export function usePath() {
  const { pathname } = useRouter(['pathname']);
  return pathname;
}

export function useSearch() {
  const { search } = useRouter(['search']);

  return useMemo(() => {
    // TODO use a global listener for the useHistorySubscription and include qs
    const qs = RouteUtils.parseQueryString(search) as Record<string, string>;
    return createProxy(() => qs, {
      onGet(key) {
        if (key === 'toString') return (() => search) as unknown as string;
        return qs[key] ?? null;
      },
    });
  }, [search]);
}

export function useMatchRoute() {
  const router = useRouter(['pathname']);

  return function matchRoute(url: string) {
    const { pathname } = RouteUtils.parseURL(url);
    return pathname === router.pathname;
  };
}

export type NavigateObject<Route extends RouteKey> = {
  to: Route;
  search?: Record<string, string> | string;
  replace?: boolean;
};

export type NavigateParam<Route extends RouteKey> =
  | NavigateObject<Route>
  | Route;

export type NavigateOptions = { replace?: boolean };

export function useNavigate() {
  const context = useRouterContext();

  return useCallback(
    function navigate<Route extends RouteKey>(
      config: NavigateParam<Route>,
      options?: NavigateOptions,
    ) {
      const { url, replace } = (() => {
        if (typeof config === 'string') {
          return {
            url: config,
            ...options,
          };
        }
        return {
          url: createPath({
            pathname: config.to as string,
            search: config.search
              ? typeof config.search === 'string'
                ? config.search
                : RouteUtils.stringifyQueryString(config.search)
              : undefined,
          }),
          ...options,
        };
      })();
      const action = replace ? 'replace' : 'push';
      context.router.$history[action](url);
    },
    [context],
  );
}

export type RouteNotFoundProps = {
  pathname: string;
};

export function RouterSlot(props: {
  NotFound(props: RouteNotFoundProps): ReactNode;
}) {
  const { NotFound } = props;
  const { match, pathname, key } = useRouter(['pathname']);
  const Component = match?.Component;
  if (!Component) return React.createElement(NotFound, { pathname, key });
  return <Component key={key} />;
}
