import { css } from '@linaria/core';
import { styled } from '@linaria/react';

import { useRendered } from '@zazcart/commons';
import { IS_BROWSER } from 'powership';
import * as React from 'react';
import { lazy } from 'react';

// @onlyClient
const SwipeableDrawer = lazy(() => import('@mui/material/SwipeableDrawer'));

const drawerBleeding = 25;

interface BottomSheetProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  children: React.ReactNode;
  container?: HTMLElement | null;
}

const Root = styled.div`
  height: 100%;
  padding-bottom: var(--gap);
`;

const ContentBox = styled.div`
  margin-top: 50px;
  padding: var(--gap);
  padding-top: 1px;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: var(--color-background-box-elevation-accent);
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  position: absolute;
  top: 3px;
  height: 48px;
  border-top-left-radius: var(--content-rounding);
  border-top-right-radius: var(--content-rounding);
  visibility: visible;
  right: 0;
  left: 0;
  background-color: var(--color-background-box-elevation-accent);
`;

const Puller = styled.div`
  width: 30px;
  height: 6px;
  background-color: var(--color-text-disabled);
  border-radius: 3px;
  position: absolute;
  top: 8px;
  left: calc(50% - 15px);
`;

const globalStyles = css`
  :global() {
    .MuiDrawer-root {
      .MuiDrawer-paper {
        background-color: transparent;
      }

      > .MuiPaper-root {
        --padding: 2px;
        height: calc(100vh - 100px);
        max-width: calc(100% - var(--padding) * 2);
        overflow: visible;
        margin-left: var(--padding);
      }
    }
  }
`;

export function BottomSheet(props: BottomSheetProps) {
  const { open, children, setOpen, container } = props;

  if (!IS_BROWSER) return null;
  if (!useRendered()) return null;

  return (
    <Root className={globalStyles}>
      <SwipeableDrawer
        container={
          container ||
          document.getElementById('BottomSheetArea') ||
          document.body
        }
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: false,
        }}
      >
        <Header>
          <Puller />
        </Header>

        <ContentBox>{children}</ContentBox>
      </SwipeableDrawer>
    </Root>
  );
}
