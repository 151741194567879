import { styled } from '@linaria/react';
import {
  cx,
  DivProps,
  observeIntersection,
  useRendered,
} from '@zazcart/commons';
import { Unsubscribe } from 'powership';
import * as React from 'react';
import { JSX, useEffect, useRef, useState, useTransition } from 'react';
import { ProductImage } from '~/lib/shopify/types.ts';

export type ImageLoaderProps = Omit<JSX.IntrinsicElements['img'], 'src'> & {
  className?: string;
  wrapperProps?: DivProps;
  image: ProductImage | null | undefined;
};

const Wrapper = styled.div`
  display: inline-flex;
  background: rgba(127, 127, 127, 0.11);
  position: relative;
  background-size: cover;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    animation: blurIn 500ms ease-in;
    background: transparent;
    z-index: 1;
    display: block;
    position: absolute;
  }
`;

export function ImageLoader(props: ImageLoaderProps) {
  const { className, alt = '', image, wrapperProps, ...others } = props;

  const src = image?.url;
  const colors = image?.colors;

  const [showImage, setShowImage] = useState(false);
  const [, initTransition] = useTransition();

  const unsubscribe = useRef<Unsubscribe[]>([]);

  const rendered = useRendered();

  useEffect(() => {
    return () => unsubscribe.current.forEach((el) => el());
  }, []);

  return (
    <Wrapper
      ref={(ref) => {
        if (!ref || !src) return;
        unsubscribe.current.push(
          observeIntersection(ref, (inView) => {
            if (inView) {
              const image = new Image();
              image.src = src;
              image.onload = () => {
                initTransition(() => {
                  setShowImage(true);
                });
              };
            }
          }),
        );
      }}
      className={cx('ImageLoader img', wrapperProps?.className, className)}
      style={{ background: rendered ? colors?.gradient : undefined }}
    >
      {showImage && (
        <img alt={alt} src={src} {...others} className={cx('img', className)} />
      )}
    </Wrapper>
  );
}
