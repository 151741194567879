import { styled } from '@linaria/react';
import { cx, ElementProps } from '@zazcart/commons';
import { Heading } from '@zazcart/ui';
import React, { ReactNode } from 'react';

const Title = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: var(--space-800) var(--gap);
  padding-bottom: var(--gap);
  max-width: var(--center-container-width);
`;

export type PageTitleProps = ElementProps<{
  lineClamp?: number;
  title?: ReactNode;
  subtitle?: ReactNode;
}>;

export function PageTitle(props: PageTitleProps) {
  const { title, children, subtitle, lineClamp, className, ...others } = props;
  return (
    <Title {...others} className={cx('PageTitle', className)}>
      <Heading>
        {title || children}

        {(() => {
          if (!subtitle) return null;
          return (
            <>
              <br />
              <small>{subtitle}</small>
            </>
          );
        })()}
      </Heading>
    </Title>
  );
}
