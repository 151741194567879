import './instrument-client.tsx';
//
import { throttle } from 'lodash-es';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { RouterProvider } from '~/components/RouterProvider.tsx';
import { AppProps, RootDataProvider } from '~/html-template.tsx';

const props: AppProps = {
  clientRef: {},
  productionAssets: null,
  ssrMethodsContext: null,
  initialState: window.INSTATE,
};

ReactDOM.hydrateRoot(
  document.getElementById('root')!,
  <RootDataProvider rootProps={props}>
    <RouterProvider url={window.location.href} />
  </RootDataProvider>,
);

function initScrollHandler() {
  let scrolled = false;
  let lastScrollPosition = [0, 0] as [number, number];
  let scrollingDown = true;

  const handleScroll = throttle(function handleScroll() {
    requestAnimationFrame(() => {
      let [lastY] = lastScrollPosition;
      let scrollY = document.body.scrollTop;
      let scrollX = document.body.scrollLeft;
      lastScrollPosition = [scrollY, scrollX];

      const nextScrollingDown = lastY > scrollY;

      if (nextScrollingDown !== scrollingDown) {
        scrollingDown = nextScrollingDown;
        document.body.classList[nextScrollingDown ? 'remove' : 'add'](
          'page-scrolling-down',
        );
        document.body.classList[nextScrollingDown ? 'add' : 'remove'](
          'page-scrolling-up',
        );
        document.body.style.setProperty(
          '--page-scrolling-up',
          nextScrollingDown ? '1' : '0',
        );
        document.body.style.setProperty(
          '--page-scrolling-down',
          nextScrollingDown ? '0' : '1',
        );
      }

      let below = document.body.scrollTop >= 2;

      // var(--gap) + var(--gap) * var(--page-not-scrolled)
      if (!scrolled && below) {
        scrolled = true;
        document.body.classList.add('page-scrolled');
        document.body.classList.remove('not-scrolled');
        document.body.style.setProperty('--page-scrolled', '1');
        document.body.style.setProperty('--page-not-scrolled', '0');
      }

      if (scrolled && !below) {
        scrolled = false;
        document.body.classList.remove('page-scrolled');
        document.body.classList.add('not-scrolled');
        document.body.style.setProperty('--page-scrolled', '0');
        document.body.style.setProperty('--page-not-scrolled', '1');
      }
    });
  }, 100);

  handleScroll();
  document.body.addEventListener('scroll', handleScroll);
}
