import { defineGetters } from 'powership';
import { Product, ProductVariantOption } from '~/lib/shopify/types.ts';

export function productLink(product: Product) {
  let link = `/products/${product.handle}`;
  const defaultVariantOptionsHelper = getDefaultVariantOptionsHelper(product);
  if (defaultVariantOptionsHelper) {
    return `${link}${defaultVariantOptionsHelper.queryString}`;
  }
  return link;
}

export function getVariantOptionsHelper(options: ProductVariantOption[]) {
  let result = defineGetters(
    {},
    {
      list: () => {
        return options.map((el) => {
          return [el.name.toLowerCase(), el.value];
        }) as [string, string][];
      },
      search: (): URLSearchParams => {
        return new URLSearchParams(result.list);
      },
      queryString: (): string => {
        return `?${result.search.toString()}`;
      },
      map: (): Map<string, string> => {
        return new Map(result.list);
      },
    },
  );
  return result;
}

export function getDefaultVariantOptionsHelper(product: Product) {
  let selectedOptions = product.variants?.[0]?.selectedOptions;
  if (selectedOptions?.length) {
    return getVariantOptionsHelper(selectedOptions);
  }
  return null;
}
