import './sidebar.scss';

import { useMatchRoute, useParams } from '@zazcart/commons';
import { SideNavigation } from '@zazcart/gestalt';
import { Link } from '@zazcart/ui';
import * as React from 'react';
import { Logo } from '~/components/logo.tsx';
import { useMethod } from '~/core/use-method.tsx';

type LinkProps = { href: string; label: string; active?: boolean };

export function SidebarDefault(props: {
  onClickLink?: (props: LinkProps) => void;
}) {
  const { onClickLink } = props;
  const collections = useMethod('getCollections', {});

  const { collection } = useParams('collections', 'not_strict');

  const matchRoute = useMatchRoute();

  function renderItem(props: LinkProps) {
    const { label, href, active } = props;
    return (
      <SideNavigation.TopItem
        key={href + label}
        href={href}
        label={label}
        active={(active ?? matchRoute(href)) ? 'page' : undefined}
        onClick={() => {
          onClickLink?.(props);
        }}
      />
    );
  }

  return (
    <div className="SidebarWrapper left-sidebar">
      <div className="SidebarContainer">
        <div className="SidebarHeader">
          <div className="HeaderDesk">
            <Link href={'/'}>
              <Logo />
            </Link>
          </div>
        </div>

        <div className="SidebarBody">
          <SideNavigation accessibilityLabel="Menu">
            {renderItem({ href: '/', label: 'Home' })}
            <>
              {collections.data.map((data) =>
                data.title === 'All'
                  ? null
                  : renderItem({
                      href: data.path,
                      label: data.title,
                      active: collection === data.handle,
                    }),
              )}
            </>
            {renderItem({ href: '/cart', label: 'Carrinho' })}
            {renderItem({ href: '/orders', label: 'Meus pedidos' })}
            {renderItem({ href: '/terms', label: 'Termos' })}
          </SideNavigation>
        </div>

        <div className="SidebarFooter" />
      </div>
    </div>
  );
}
