import { useEffect, useState, useTransition } from 'react';

export function useRendered() {
  const [mounted, setMounted] = useState(false);
  const [, start] = useTransition();

  useEffect(() => {
    setTimeout(() => {
      start(() => {
        setMounted(true);
      });
    }, 500);
  }, []);

  return mounted;
}
