import * as React from 'react';
import { CartRow } from '~/components/CartRows.tsx';
import { CartStateItem, useShoppingCart } from '~/state';

export function CartProducts(props: { items?: CartStateItem[] }) {
  const { items: itemsProp } = props;
  const { cartItems } = useShoppingCart();

  return (
    <div className={'CartProducts CartRows'}>
      {(itemsProp || cartItems).map((el) => {
        const { id } = el;
        return <CartRow key={id} cartItem={el} />;
      })}
    </div>
  );
}
