import './FormField.scss';

import React from 'react';
import { FormInput, FormInputProps } from '~/ariakit.tsx';
import { FieldWrapper, FormWrapperProps } from '~/FieldWrapper.tsx';
import { MergeProps } from '~/utils';

export type FormFieldProps = MergeProps<FormInputProps, FormWrapperProps>;

export const FormField = React.forwardRef<HTMLInputElement, FormFieldProps>(
  (props, ref) => {
    const {
      name,
      required,
      type,
      hidden = type === 'hidden',
      children,
    } = props;

    return (
      <FieldWrapper {...props} ref={ref}>
        {(inputProps) => {
          return children === undefined ? (
            <FormInput
              {...inputProps}
              type={type}
              name={name}
              required={required}
              hidden={hidden}
            />
          ) : React.isValidElement(children) ? (
            // @ts-ignore
            React.cloneElement(children, { ref })
          ) : (
            children
          );
        }}
      </FieldWrapper>
    );
  },
);

FormField.displayName = 'FormField';
