import './sidebar.scss';

import { BottomSheet, IconButton } from '@zazcart/ui';
import React, { useState } from 'react';
import { SidebarDefault } from '~/components/sidebar/sidebar-default.tsx';

export default function MenuSidebar() {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <IconButton
        accessibilityLabel={'open menu'}
        icon={'menu'}
        onClick={() => {
          setIsOpen(true);
        }}
      />

      <BottomSheet open={isOpen} setOpen={setIsOpen}>
        <SidebarDefault onClickLink={() => setIsOpen(false)} />
      </BottomSheet>
    </>
  );
}
