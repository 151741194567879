import './FormError.scss';

import * as React from 'react';

import { MergeProps } from '~/utils';
import { VariantProps, variantsProps } from '~/variants.tsx';

export type FormErrorProps = MergeProps<
  VariantProps,
  { name: string; children: string | undefined }
>;

export function FormError(props: FormErrorProps) {
  return (
    <div role={'alert'} {...variantsProps('FormError', props)}>
      {props.children}
    </div>
  );
}
