import './AppLayout.scss';

import { cx, useRendered } from '@zazcart/commons';
import { BottomSheet, HeadingLevel, used } from '@zazcart/ui';
import { PropsWithChildren, default as React, ReactNode } from 'react';
import { PageTitle } from '~/components/AppLayout/PageTitle.tsx';
import { Loadable } from '~/components/loadable.tsx';
import { CartFooterActions } from '~/components/product/checkout/CartFooterActions.tsx';
import { CartProducts } from '~/components/product/checkout/CartProducts.tsx';
import { RestoreScroll } from '~/components/RestoreScroll.tsx';
import { useShoppingCart } from '~/state';

export type AppLayoutName = 'initial' | 'centered';

export type AppLayoutProps = {
  className?: string;
  left: ReactNode;
  hero: ReactNode;
  main: ReactNode;
  navbar: ReactNode;
  right: ReactNode;
  layout?: AppLayoutName;
  title?: ReactNode;
  subtitle?: ReactNode;
};

export function AppLayout(props: AppLayoutProps) {
  const { className, main, hero, left, navbar, right, title, layout } = props;

  return (
    <HeadingLevel level={1}>
      <div className={cx('AppLayout', className)}>
        {left !== null && (
          <div id="left-sidebar" className="AppLayout_sidebar">
            {left}
          </div>
        )}

        <main
          id="main-content"
          className={cx('AppLayout_mainCenter', {
            centered: title || layout === 'centered',
            'has-navbar': navbar,
            'has-hero': hero,
            'has-right-sidebar': right,
            'has-left-sidebar': left,
          })}
        >
          {navbar !== null && <div className="AppLayout_navbar">{navbar}</div>}

          <>
            {hero !== null && <div className="AppLayout_hero">{hero}</div>}

            {(() => {
              if (!main) return null;
              if (title) {
                return (
                  <div className="AppLayout_mainCenter">
                    <PageTitle {...props} />
                    <section className="AppLayout_centerContainer">
                      {main}
                    </section>
                  </div>
                );
              }
              return <div className="AppLayout_mainCenter">{main}</div>;
            })()}
          </>
        </main>

        <Loadable>
          <Right>{right}</Right>
        </Loadable>

        <RestoreScroll />
      </div>
    </HeadingLevel>
  );
}

function Right(props: PropsWithChildren) {
  let { children } = props;

  const {
    layout: { showCart },
    setOpen,
  } = useShoppingCart();

  const desk = used();
  const mobile = !desk;
  const rendered = useRendered();

  return (
    <>
      {!showCart && !!children && (
        <div id="right-sidebar" className={'AppLayout_sidebar'}>
          {children}
        </div>
      )}

      {showCart && desk ? (
        <div className="AppLayout_sidebar">
          <section className="AppLayout_deskCartWrapper">
            <header className="AppLayout_cart-header">
              <h1>Meu carrinho</h1>
            </header>

            <CartProducts />
            <CartFooterActions />
          </section>
        </div>
      ) : null}

      {rendered && mobile && (
        <BottomSheet
          open={showCart}
          setOpen={(open) => {
            setOpen(open);
          }}
        >
          <CartProducts />
          <CartFooterActions />
        </BottomSheet>
      )}
    </>
  );
}

// const Sidebar = () => {
//   const [isOpen, setIsOpen] = React.useState(true);
//   const menu = useMenuStore();
//
//   const menuItems = [
//     { icon: <Icon icon={'home'} size={20} />, label: 'Início', href: '/' },
//     {
//       icon: <Icon icon="person" size={20} />,
//       label: 'Perfil',
//       href: '/perfil',
//     },
//     {
//       icon: <Icon icon="target" size={20} />,
//       label: 'Configurações',
//       href: '/configuracoes',
//     },
//     {
//       icon: <Icon icon="speech-exclamation-point" size={20} />,
//       label: 'Ajuda',
//       href: '/ajuda',
//     },
//   ];
//
//   return (
//     <Menu store={menu} style={styles.nav} aria-label="Navegação principal">
//       {menuItems.map((item) => (
//         <MenuItem key={item.label} style={styles.menuItem}>
//           {item.icon}
//           {isOpen && <span style={styles.menuItemLabel}>{item.label}</span>}
//         </MenuItem>
//       ))}
//     </Menu>
//   );
// };
