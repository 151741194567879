import { useCallback, useEffect, useMemo, useRef } from 'react';

export function useThrottle<Fn extends (...args: any) => void>(
  fn?: Fn,
  time = 100,
  dep: any = null,
): Fn {
  const ref = useRef<any>();

  const clear = useCallback(() => {
    clearTimeout(ref.current);
  }, []);

  useEffect(() => {
    return clear;
  }, [clear, dep]);

  return useMemo<any>(() => {
    return function throttled(this: any, ...args: any) {
      clear();

      ref.current = setTimeout(() => {
        fn?.apply(this, args);
      }, time);
    };
  }, [clear, time, fn, dep]);
}
