import './Heading.scss';

import {
  Heading as HeadingBase,
  HeadingProps as HeadingPropsBase,
} from '@ariakit/react';
import React from 'react';
import { MergeProps, VariantProps, variantsProps } from '~/utils';

export type HeadingProps = MergeProps<
  HeadingPropsBase,
  VariantProps,
  {
    lineClamp?: string | number;
  }
>;

export function Heading(props: HeadingProps) {
  const { lineClamp, ...otherProps } = props;

  return (
    <HeadingBase
      {...variantsProps(
        'Heading',
        otherProps,
        lineClamp ? `line-clamp-${lineClamp}` : undefined,
      )}
    />
  );
}
