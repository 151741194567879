import './Icon.scss';

import React from 'react';
import { variantsProps } from '~/utils';

import { cx, ElementProps } from '@zazcart/commons';
import { IconName, icons } from './icons';

// const SIZE_NAME_TO_PADDING_PIXEL = {
//   xs: 6,
//   sm: 8,
//   md: 11,
//   lg: 14,
//   xl: 16,
// } as const;

const SIZE_NAME_TO_ICON_SIZE_PIXEL = {
  xs: 12,
  sm: 16,
  md: 18,
  lg: 20,
  xl: 24,
} as const;

export type IconColor =
  | 'default'
  | 'disabled'
  | 'subtle'
  | 'success'
  | 'error'
  | 'warning'
  | 'info'
  | 'recommendation'
  | 'inverse'
  | 'shopping'
  | 'brandPrimary'
  | 'light'
  | 'dark';

export type IconProps = ElementProps<
  {
    icon: IconName;
    color?: IconColor;
    accessibilityLabel: string;
    padding?: 1 | 2 | 3 | 4 | 5;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  },
  'svg'
>;

export function Icon(props: IconProps) {
  const {
    icon,
    size = 'md',
    color: iconColor = 'transparent',
    padding,
    accessibilityLabel,
    ...otherProps
  } = props;
  const component = icons[icon];
  if (!component) throw new Error(`Icon with name ${icon} not found.`);

  const iconSizeInPx = SIZE_NAME_TO_ICON_SIZE_PIXEL[size];
  const sizeInPx = iconSizeInPx;

  const inlineStyle = {
    height: sizeInPx,
    width: sizeInPx,
  } as const;

  return React.createElement(component, {
    ...otherProps,
    size: iconSizeInPx,
    'aria-label': accessibilityLabel || undefined,
    style: { ...inlineStyle, ...otherProps.style },
    className: cx('Icon', otherProps.className),
  });
}
