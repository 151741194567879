import { cx, ElementProps } from '@zazcart/commons';
import { memoize } from 'powership';
import React from 'react';
import { MergeProps } from '~/utils';
import {
  breakpoints,
  resolveResponsiveValue,
  ResponsiveValue,
  SpaceToken,
  VariantProps,
  variantsProps,
} from './variants.tsx';

type TemplateLiteral = number | string;

type TemplateShortHand =
  | `${TemplateLiteral}/${TemplateLiteral}`
  | `${TemplateLiteral}/${TemplateLiteral}/${TemplateLiteral}`
  | `${TemplateLiteral}/${TemplateLiteral}/${TemplateLiteral}/${TemplateLiteral}`;

type TemplateValue = TemplateLiteral | TemplateShortHand;

type Template =
  | TemplateShortHand
  | TemplateValue[]
  | ResponsiveValue<TemplateValue[]>;

export type GridProps = MergeProps<
  VariantProps,
  ElementProps,
  {
    children: React.ReactNode;
    container?: boolean;
    item?: boolean;
    columns?: ResponsiveValue<number>;
    spacing?: ResponsiveValue<SpaceToken>;
    template?: Template;
  }
>;

const formatTemplateValue = memoize((value: TemplateValue) =>
  `${value}`.match(/^\d+$/) ? `${value}px` : value,
);

const formatTemplate = memoize(
  (template: TemplateValue[] | TemplateShortHand) => {
    if (typeof template === 'string')
      return template
        .split('/')
        .map((el) => `${el}fr`)
        .join(' ');

    return template.map(formatTemplateValue).join(' ');
  },
);

export const Grid = React.memo<GridProps>(
  ({
    children,
    container,
    item,
    columns = 1,
    spacing,
    direction,
    justify,
    align,
    className,
    template,
    ...props
  }) => {
    const templateStyles = template
      ? typeof template === 'object' && !Array.isArray(template)
        ? Object.entries(template).reduce(
            (acc, [breakpoint, values]) => ({
              ...acc,
              [`@media (min-width: ${
                // @ts-ignore
                breakpoint === 'xs' ? '0' : breakpoints[breakpoint]
              })`]: {
                gridTemplateColumns: formatTemplate(values),
              },
            }),
            {},
          )
        : { gridTemplateColumns: formatTemplate(template as TemplateValue[]) }
      : {};

    const classes = cx(
      'Grid',
      'grid',
      container && 'grid-container',
      item && 'grid-item',
      !template &&
        resolveResponsiveValue(columns, (cols) => `grid-cols-${cols}`),
      className,
      variantsProps('', {
        display: 'grid',
        direction,
        justify,
        align,
        gap: spacing,
        ...(props as any),
      }).className,
    );

    return (
      <div className={classes} style={templateStyles}>
        {children}
      </div>
    );
  },
);

Grid.displayName = 'Grid';
