import { AnyFunction, IS_BROWSER } from 'powership';

export type DelayOptions = {
  throttle?: number;
  awaitLimit?: number;
  requestAnimationFrame?: boolean;
  onlyClient?: boolean; // default true
};

export function withDelay<Fn extends AnyFunction>(
  this: any,
  run: Fn,
  options: DelayOptions = {
    requestAnimationFrame: true,
    onlyClient: true,
    throttle: 100,
    awaitLimit: 1000,
  },
) {
  let raf: number;
  let timeout: ReturnType<typeof setTimeout>;
  let timeoutAwaitLimit: ReturnType<typeof setTimeout> | null = null;

  const cancel = () => {
    if (raf) {
      cancelAnimationFrame(raf);
    }
    clearTimeout(timeout);
    // @ts-ignore
    clearTimeout(timeoutAwaitLimit);
    timeoutAwaitLimit = null;
  };

  const exec = (params: Parameters<Fn>) => {
    cancel();
    run(...params);
  };

  function executeRaf(args: any) {
    if (typeof requestAnimationFrame === 'function') {
      raf = requestAnimationFrame(() => {
        exec(args);
      });
      return;
    } else {
      options = { ...options, throttle: 50, requestAnimationFrame: false };
    }
  }

  function executeInterval(args: any) {
    if (options.awaitLimit !== undefined && timeoutAwaitLimit === null) {
      // clear only on exec
      timeoutAwaitLimit = setTimeout(() => {
        cancel();
        exec(args);
      }, options.awaitLimit);
    }

    clearTimeout(timeout);

    timeout = setTimeout(() => {
      exec(args);
    }, options.throttle);
  }

  function executor(...args: Parameters<Fn>) {
    if (!IS_BROWSER && options.onlyClient !== false) {
      return;
    }

    if (options.requestAnimationFrame) {
      return executeRaf(args);
    } else {
      return executeInterval(args);
    }
  }

  executor.cancel = cancel;
  executor.now = (...params: any) => exec(params);

  return executor as unknown as ((...args: Parameters<Fn>) => void) & {
    cancel(): void;
    now(...args: Parameters<Fn>): void;
  };
}
